<template>
	<div>
		<van-cell-group>			
			<van-cell v-if="userInfo.memberType!=2" @click="toRoute({url:'/sample',state:0,title:'送样员管理'})" :icon="require('@/assets/images/setUp/setup1.png')" title="送样员管理" is-link />
			<van-cell v-for="(item,index) in setUpList" :key="index" @click="toRoute(item)" :icon="item.icon" :title="item.title" is-link />
		</van-cell-group>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "更多设置",
				backcolor: "#FFFFFF",
				businessStatus:0,//0 未认证 2 审核中 6 已认证  7 审核失败
				userInfo:{},
				setUpList:[
				// 	{
				// 	icon:require('@/assets/images/setUp/setup1.png'),
				// 	title:"送样员管理",
				// 	url:"/sample",
				// 	state:0
				// },
				{
					icon:require('@/assets/images/setUp/setup2.png'),
					title:"意见反馈",
					url:"/opinion",
					state:-1
				},
				{
					icon:require('@/assets/images/setUp/setup3.png'),
					title:"地址管理",
					url:"/adress",
					state:-1
				},
				{
					icon:require('@/assets/images/setUp/setup4.png'),
					title:"修改资料",
					url:"/means",
					state:-1
				},
				{
					icon:require('@/assets/images/setUp/setup5.png'),
					title:"常见问题",
					url:"/agreement",
					state:5
				},
				{
					icon:require('@/assets/images/setUp/setup6.png'),
					title:"用户协议",
					url:"/agreement",
					state:3
				},
				{
					icon:require('@/assets/images/setUp/setup7.png'),
					title:"隐私协议",
					url:"/agreement",
					state:4
				},
				{
					icon:require('@/assets/images/setUp/setup8.png'),
					title:"使用说明",
					url:"/agreement",
					state:6
				},
				{
					icon:require('@/assets/images/setUp/setup9.png'),
					title:"关于我们",
					url:"/agreement",
					state:2
				}]
			}
		},
		created() {
			this.getUserInfo()
		},
		methods: {
			//查询用户信息，用于判断商家认证
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					console.log("用户信息",res)
					this.userInfo=res.data
					this.businessStatus=res.data.businessState
					
				})
			},
			//跳转
			toRoute(item){
				if(item.state==0){
					if(this.businessStatus!=6){
						Toast("商家未认证，请前往认证")
						return;
					}
					
				}
				if(item.state==-1){
					this.$router.push({
						path:item.url
					})
				}else{
					this.$router.push({
						path:item.url,
						query:{
							title:item.title,
							state:item.state
						}
					})
				}
			},
			
		}
	}
</script>

<style>
</style>
