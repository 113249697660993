<template>
	<div>
		<div v-if="loadingBig">
			<loadingBig></loadingBig>
		</div>
		<div v-else>
			<div class="orderDetailTop">
				<div class="flex-row" style="width: 80px;margin: auto;padding-top: 40px;">
					<!-- <img :src="orederDetail1" /> -->
					<img style="width: 30px;height: 30px;" :src="orderSrate[index].image" />
					<div style="margin: auto;">{{orderSrate[index].name}}</div>
				</div>
		
		
			</div>
			<div class="width82 backgroundWhite radius5 text-14" style="margin-top: -60px;">
				<div class="rowCenter">
					<div class="gray" style="width: 60px;margin-right: 20px;">送样人</div>
					<div>{{orderInfo.sampleSender}}</div>
				</div>
				<div class="rowCenter paddingtb8">
					<div class="gray " style="width: 60px;margin-right: 20px;">联系电话</div>
					<div>{{orderInfo.tel}}</div>
				</div>
				<div class="rowCenter">
					<div class="gray" style="width: 60px;margin-right: 20px;">送检站点</div>
					<div>{{orderInfo.siteName}}</div>
				</div>
			</div>
		
			<div class="width82 backgroundWhite radius5 text-14" style="margin-top: 10px;">
				<div class="rowCenter">
					<img class="img14" src="../../../assets/images/subscribe.png" />
					<div class="blod margin-left-8">送检信息</div>
				</div>
		
				<!-- <div class="betweenCenter paddingtb8">
					<div class="gray">材质</div>
					<div>{{orderInfo.textureOfMaterial?orderInfo.textureOfMaterial:"无"}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">贵金属种类</div>
					<div>{{orderInfo.nobleMetalType?orderInfo.nobleMetalType:"无"}}</div>
				</div> -->
				<div class="betweenCenter paddingtb8">
					<div class="gray">检验标识</div>
					<div>{{orderInfo.actualInspectionMark}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">样品数</div>
					<div>{{orderInfo.sampleNumber}}</div>
				</div>
		
				<div class="betweenCenter paddingtb8">
					<div class="gray">测金数</div>
					<div>{{orderInfo.goldNumber}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">测配石数</div>
					<div>{{orderInfo.stoneNumber}}</div>
				</div>
				<div class="betweenCenter paddingtb8">
					<div class="gray">大型仪器检测数</div>
					<div>{{orderInfo.instrumentNumber}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">三维视频</div>
					<div>{{orderInfo.threeDimensionalVideo}}</div>
				</div>
				<div class="betweenCenter paddingtb8">
					<div class="gray">其他</div>
					<div>{{orderInfo.otherNumber}}</div>
				</div>
		
			</div>
		
			<div class="width82 backgroundWhite radius5 text-14" style="margin-top: 10px;" v-if="orderInfo.state==16||orderInfo.state==18||orderInfo.state==21">
				<div class="rowCenter">
					<img class="img14" src="../../../assets/images/actual.png" />
					<div class="blod margin-left-8">实际检验</div>
				</div>
		
				<!-- <div class="betweenCenter paddingtb8">
					<div class="gray">材质</div>
					<div>{{orderInfo.actualTextureOfMaterial}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">贵金属种类</div>
					<div>{{orderInfo.actualNobleMetalType}}</div>
				</div> -->
				<div class="betweenCenter paddingtb8">
					<div class="gray">检验标识</div>
					<div>{{orderInfo.actualInspectionMark}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">样品数</div>
					<div>{{orderInfo.actualSampleNumber}}</div>
				</div>
		
				<div class="betweenCenter paddingtb8">
					<div class="gray">测金数</div>
					<div>{{orderInfo.actualGoldNumber}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">测配石数</div>
					<div>{{orderInfo.actualStoneNumber}}</div>
				</div>
				<div class="betweenCenter paddingtb8">
					<div class="gray">大型仪器检测数</div>
					<div>{{orderInfo.actualInstrumentNumber}}</div>
				</div>
				<div class="betweenCenter">
					<div class="gray ">三维视频</div>
					<div>{{orderInfo.actualThreeDimensionalVideo}}</div>
				</div>
				<div class="betweenCenter paddingtb8">
					<div class="gray">其他</div>
					<div>{{orderInfo.actualOtherNumber}}</div>
				</div>
		
			</div>
		
			<div class="width82 backgroundWhite radius5 text-14" style="margin-top: 10px;">
				<div class="rowCenter">
					<img class="img14" src="../../../assets/images/cost.png" />
					<div class="blod margin-left-8">费用明细</div>
				</div>
		
				<div class="borderBottom">
					<div class="betweenCenter paddingtb8">
						<div class="gray">加急费用</div>
						<div class="orange">￥{{orderInfo.urgentCost}}</div>
					</div>
					<div class="betweenCenter">
						<div class="gray ">检测费</div>
						<div>￥{{orderInfo.testingCost}}</div>
					</div>
					<div class="betweenCenter paddingtb8">
						<div class="gray">测金费</div>
						<div>￥{{orderInfo.testGoldCost}}</div>
					</div>
					<div class="betweenCenter">
						<div class="gray ">测配石费</div>
						<div>￥{{orderInfo.testStoneCost}}</div>
					</div>
		
					<div class="betweenCenter paddingtb8">
						<div class="gray">大型仪器检测费</div>
						<div>￥{{orderInfo.testInstrumentCost}}</div>
					</div>
					<div class="betweenCenter">
						<div class="gray ">三维视频</div>
						<div>￥{{orderInfo.testThreeDimensionalVideoCost}}</div>
					</div>
					<div class="betweenCenter paddingtb8">
						<div class="gray ">证书费用</div>
						<div>￥{{orderInfo.printCertificateCost}}</div>
					</div>
					<div class="betweenCenter ">
						<div class="gray">物流</div>
						<div>￥{{orderInfo.logisticsCost?orderInfo.logisticsCost:0}}</div>
					</div>
					<div class="betweenCenter paddingtb8">
						<div class="gray">其他</div>
						<div>￥{{orderInfo.otherCost}}</div>
					</div>
				</div>
		
				<div class="text-16 padding-top-10" style="text-align: right;">
					<span>合计预估费用:</span>
					<span class="orange">￥{{orderInfo.totalCost}}</span>
				</div>
			</div>
		
			<div class="width82 backgroundWhite radius5 text-14" style="margin-top: 10px;">
				<div class="blod">订单信息</div>
		
				<div class="gray text-14">
					<div class="padding-top-8">订单状态:{{orderSrate[index].name}}</div>
					<div class="padding-top-8">订单编号:{{orderInfo.orderCode}}</div>
					<div class="padding-top-8" v-if="orderInfo.state!=1&&orderInfo.state!=27">结算方式:{{orderInfo.settlementType==2?"现付":orderInfo.settlementType==3?"月结":orderInfo.settlementType==4?"挂账":"其他"}}</div>
					<div class="padding-top-8">创建时间:{{orderInfo.createDate}}</div>
					<div class="padding-top-8" v-if="orderInfo.state!=1&&orderInfo.state!=27">确认时间:{{orderInfo.confirmDate}}</div>
					<div class="padding-top-8" v-if="orderInfo.state==21">支付时间:{{orderInfo.payDate}}</div>
					<div class="padding-top-8" v-if="orderInfo.state==27">取消时间:{{orderInfo.closeDate}}</div>
				</div>
		
			</div>
		
			<!-- 底部按钮 -->
			<div style="height: 50px;"></div>
			<div class="detailBottom" v-if="orderInfo.state==1">
				<div class="solidBtn" @click="clickCancel" style="position: absolute;right: 5vw;bottom: 5px;">取消订单</div>
			</div>
		
			<div class="detailBottom" v-if="orderInfo.state==21">
				<div class="solidBtn" style="position: absolute;right: 5vw;bottom: 5px;" @click="toOpinion">意见反馈</div>
			</div>
		
			<div class="detailBottom betweenCenter" v-if="orderInfo.state==16">
				<div class="text-14">
					<span>共</span>
					<span class="orange">{{orderInfo.actualSampleNumber}}</span>
					<span>件，合计费用:</span>
					<span class="orange">￥{{orderInfo.totalCost}}</span>
				</div>
				<div class="solidBtn" @click="clickPay">取样付款</div>
			</div>
		
			<!-- 支付方式 -->
			<van-action-sheet v-model="showPayment" title="选择支付方式" >
				<payment :price="orderInfo.totalCost" :showBalance="showBalance" :showIntegral="showIntegral" :integralPrice="integralPrice" :orderId="orderId" :orderType="4" @paySuccess="paySuccess"></payment>
			</van-action-sheet>
		
		</div>
	</div>
	
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import payment from "@/components/payment.vue"
	import loadingBig from "@/components/loading-big.vue"
	import { Dialog } from 'vant';
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		components:{
			payment,loadingBig
		},
		data() {
			return {
				title: "送检订单",
				state: 0,
				loadingBig:true,//页面加载
				showTips: false,
				showPayment: false,
				integralPrice:0,//积分支付
				radioPayment:"1",
				index:0,
				orderSrate:[
					{
						image:require('@/assets/images/order/submissionOrderDetail1.png'),
						name:"待确认"
					},
					{
						image:require('@/assets/images/order/submissionOrderDetail2.png'),
						name:"检测中"
					},
					{
						image:require('@/assets/images/order/submissionOrderDetail3.png'),
						name:"待付款"
					},
					{
						image:require('@/assets/images/order/submissionOrderDetail4.png'),
						name:"待取样"
					},
					{
						image:require('@/assets/images/order/submissionOrderDetail5.png'),
						name:"已完成"
					},
					{
						image:require('@/assets/images/order/submissionOrderDetail6.png'),
						name:"已取消"
					}
				],
				orderId:0,
				orderInfo:"",
				showBalance:true,
				showIntegral:true,
			}
		},
		created() {
			this.state = parseInt(this.$route.query.state) + 1
			this.orderId = this.$route.query.orderId
			this.getOrderDetail()
			this.getUserInfo()
		},
		methods: {
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					var userInfo=res.data
					if(userInfo.memberType==2){
						//送样员
						this.showBalance=false
						this.showIntegral=false
					}
				})
			},
			//查询订单详情
			getOrderDetail(){
				this.loadingBig=true
				this.$axios.get("/order/base/orderDetails",{
					params:{orderId:this.orderId}
				}).then(res=>{
					console.log("订单详情",res)
					setTimeout(()=>{
						this.loadingBig=false
					},500)
					this.orderInfo=res.data
					if(this.orderInfo.state==1){
						this.index=0
					}else if(this.orderInfo.state==2 || this.orderInfo.state==4){
						this.index=1
					}else if(this.orderInfo.state==16){
						this.index=2
					}else if(this.orderInfo.state==18){
						this.index=3
					}else if(this.orderInfo.state==21){
						this.index=4
					}else if(this.orderInfo.state==27){
						this.index=5
					}
				})
			},
			clickCancel() {
				Dialog.confirm({
				  title: '取消提示',
				  message: '确定取消本次送检预约?',
				  confirmButtonColor:this.defaultColor
				})
				  .then(() => {
				    // on confirm
					this.$axios.post("/order/base/cancelOrder",{
						orderId:this.orderId
					}).then(res=>{
						console.log("确定取消返回",res)
						Toast(res.msg)
						this.getOrderDetail()
					})
				  })
				  .catch(() => {
				    // on cancel
				  });
			},
			closeCancel(value) {
				this.showTips = false
				console.log("关闭弹框",value)
			},
			clickPay() {
				this.$axios.get("/pay/integral/get-points?orderId="+this.orderId).then(res=>{
					
					// this.orderId=item.id
					// this.payPrice=item.totalCost
					this.integralPrice=res.data//积分支付
					this.showPayment=true
				})
			},
			//意见反馈
			toOpinion(){
				this.$router.push({
					path:"/opinion"
				})
			},
			//支付成功
			paySuccess(){
				console.log("支付成功")
				this.showPayment=false
				this.getOrderDetail()
			}
		}
	}
</script>

<style>
	.orderDetailTop {
		width: 100vw;
		height: 170px;
		background-image: url(../../../assets/images/prsonal.png);
		background-size: 100% 100%;
		color: #FFFFFF;
		text-align: center;
	}

	.detailBottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 90vw;
		height: 30px;
		background: #FFFFFF;
		padding: 5px 5vw;
		font-size: 16px;
	}
	
</style>
