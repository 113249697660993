<template>
	<div>

		<div class="width82">
			<div style="text-align: center;padding-bottom: 8px;" v-if="custom==0">
				<span style="font-size: 18px;">￥</span>
				<span style="font-size: 32px;">{{price}}</span>
			</div>
			<div style="text-align: center;padding-bottom: 8px;" v-if="showIntegral">
				<!-- <span style="font-size: 14px;">积分支付</span> -->
				<span style="font-size: 14px;color: red;">（使用积分支付应付：{{integralPrice}}）</span>
			</div>
			<div class="blod rowCenter payment" style="padding-top: 16px;" v-if="custom==1">
				<div style="font-size: 30px;">￥</div>
				<van-field type="number" style="font-size: 28px;" v-model="payPrice" placeholder="请输入您要充值的金额" />
			</div>
			<div class="text-14">
				<van-radio-group v-model="radioPayment">
					<div class="betweenCenter borderBottom paddingtb10">
						<div>微信支付</div>
						<van-radio icon-size="16" name="1"></van-radio>
					</div>
					<div class="betweenCenter borderBottom paddingtb10" v-if="showOffline">
						<div>线下支付</div>
						<van-radio icon-size="16" name="2"></van-radio>
					</div>

					<div class="betweenCenter borderBottom paddingtb10" v-if="showBalance">
						<div>余额支付</div>
						<div class="betweenCenter">
							<div class="text999" style="padding-right: 5px;">当前可用余额:￥{{balance}}</div>
							<van-radio icon-size="16" name="3" :disabled="balance>0?false:true"></van-radio>
						</div>

					</div>
					<div class="betweenCenter borderBottom paddingtb10" v-if="showIntegral">
						<div>积分支付</div>
						<div class="betweenCenter">
							<div class="text999" style="padding-right: 5px;">当前可用积分:{{integral}}</div>
							<van-radio icon-size="16" name="4" :disabled="(integral>0&&integral>integralPrice)?false:true"></van-radio>
						</div>

					</div>
					<!-- <div class="betweenCenter borderBottom paddingtb10" v-if="showFrequency">
						<div>次数抵扣</div>
						<div class="betweenCenter">
							<div class="text999" style="padding-right: 5px;">当前可用次数:{{frequency}}</div>
							<van-radio icon-size="16" name="5" :disabled="frequency>0?false:true"></van-radio>
						</div>
					</div> -->
				</van-radio-group>
			</div>
			<!-- <div class="payBtn" @click="pay">立即支付</div> -->
			<div class="" style="width: 100%;margin: 30px 0;">
				<van-button @click="pay" :disabled="loadingBtn" style="width: 82vw;margin: auto;" color="linear-gradient(to right,#03B0F9,#0080FD)" type="primary" round>立即支付</van-button>
				
			</div>
		</div>
	</div>
</template>

<script>
	import { Toast } from 'vant';
	export default {
		data() {
			return {
				loadingBtn:false,
				radioPayment: "1",
				payPrice:null,//自定义金额
				balance: 0, //余额
				integral: 0, //积分
				frequency: 0, //次数
				// showPayment:true
			}
		},
		props: {
			//0可输入金额1不可输入
			custom: {
				type: Number,
				default: 0
			},
			//价格
			price: {
				type: Number,
				default: 0
			},
			//积分支付
			integralPrice:{
				type: Number,
				default: 0
			},
			//是否可选线下支付
			showOffline: {
				type: Boolean,
				default: true
			},
			//是否可选积分支付
			showIntegral: {
				type: Boolean,
				default: true
			},
			//是否可选次数
			showFrequency: {
				type: Boolean,
				default: true
			},
			//是否可选余额
			showBalance: {
				type: Boolean,
				default: true
			},
			//订单id
			orderId: {
				type: String,
				default: "0"
			},
			//订单类型 1 自定义余额充值 2 余额固定金额充值 3次数套餐充值 4 预约订单结算 5 证书补打付款 6 月结付款 7 记账付款
			orderType:{
				type:Number,
				default:0
			}
		},
		created() {
			console.log("price",this.price)
			this.payPrice = this.price
			this.getBalance()
			
		},
		methods: {
			
			getBalance() {
				this.$axios.get("/member/base/payUsable").then(res => {
					console.log("余额等", res)
					this.balance = res.data.balance //余额
					this.integral = res.data.integral //积分
					this.frequency = res.data.number //次数
				})
			},
			pay() {
				this.loadingBtn=true
				var param = {}
				if (this.custom == 1) {//0可输入金额1不可输入
					// price = this.payPrice
					param={
						payMoney: this.payPrice,
						payType:this.orderType
					}
				} else {
					if(this.radioPayment == 4){
						//积分支付
						param={
							orderId: this.orderId
						}
					}else{
						param={
							payId: this.orderId,
							payType:this.orderType
						}
					}
					
				}
				if(this.orderType==7){
					param.payId=this.orderId
				}
				console.log("支付方法", this.radioPayment,param)
				
				this.$emit("pay",param)

				//微信支付1;线下支付2;余额支付3;积分支付4;次数抵扣5
				if (this.radioPayment == 1) {
					this.$axios.post("/wx/h5Pay/wxH5Pay", param).then(res => {
						console.log("微信支付", res)
						if(res.code==1){
							var that=this
							window.WeixinJSBridge.invoke(
								'getBrandWCPayRequest', {
									"appId": res.data.appId, //公众号名称，由商户传入     
									"timeStamp": res.data.timeStamp, //时间戳，自1970年以来的秒数     
									"nonceStr": res.data.nonceStr, //随机串     
									"package": res.data.package,
									"signType": res.data.signType, //微信签名方式:     
									"paySign": res.data.paySign //微信签名 
								},
							
								function(res) {
									that.loadingBtn=false
									if (res.err_msg == "get_brand_wcpay_request:ok") {
										Toast("支付成功");
										that.$emit("paySuccess")
									} // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。 
									else{
										Toast("支付失败");
										that.$emit("payFail")
									}
								}
							)
						}else{
							Toast(res.msg)
						}
						
					})
				}else if(this.radioPayment == 2){//线下支付
					this.loadingBtn=false
					Toast("请联系站点人员!");
					this.$emit("paySuccess")
				}else if (this.radioPayment == 3){//余额支付
					this.$axios.post("/pay/balance/balancePay",param).then(res=>{
						console.log("余额支付",res)
						this.loadingBtn=false
						if(res.code==1){
							Toast("支付成功");
							this.getBalance()
							this.$emit("paySuccess")
						}else{
							Toast(res.msg);
							this.$emit("payFail")
						}
					})
				}else if (this.radioPayment == 4){//积分支付
					this.$axios.get("/pay/integral/integralPay",{params:param}).then(res=>{
						console.log("积分支付",res)
						this.loadingBtn=false
						if(res.code==1){
							Toast("支付成功");
							this.$emit("paySuccess")
						}else{
							Toast(res.msg);
							this.$emit("payFail")
						}
					})
				}else if (this.radioPayment == 5){//次数抵扣
					this.$axios.post("/pay/number/numberPay",param).then(res=>{
						console.log("次数抵扣",res)
						this.loadingBtn=false
						if(res.code==1){
							Toast("支付成功");
							this.getBalance()
							this.$emit("paySuccess")
						}else{
							Toast(res.msg);
							this.$emit("payFail")
						}
					})
				}
			}
		}
	}
</script>

<style>
	/* .payment .van-cell{
		border: #D7D9DF 0.5px solid;
	} */
</style>
