<template>
	<div>
		<div class="width90 opinionInput" style="margin-top: 10px;">
			<van-field v-model="message" rows="6" autosize type="textarea" maxlength="500" placeholder="您的宝贵评论、意见，将是我们进步的源泉"
			 show-word-limit />
		</div>

		<div class="backgroundWhite radius5" style="width: 82vw;padding: 10px 4vw;margin: auto;margin-top: 10px;">
			<div>上传图片（{{imgList.length}}/3）</div>
			<van-uploader :after-read="afterRead" :before-delete="deleteImg" v-model="fileList" :max-count="3" style="margin-top: 12px;" />
		</div>

		<div class="width90" style="margin-top: 70px;" @click="submitOpinion">
			<van-button :disabled ="loadingBtn" style="width: 90vw;" :color="colorBtn" type="primary" round>发布</van-button>
		</div>

	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "意见反馈",
				message: '',
				fileList: [],
				imgList:[],
				imgIdList:[]
			}
		},
		created() {

		},
		methods: {
			submitOpinion() {
				if(!this.message){
					Toast("请输入意见！")
					return;
				}
				this.loadingBtn=true
				console.log("图片",this.loadingBtn)
				// var img=this.imgList.toString()
				var imgId=this.imgIdList.toString()
				console.log("图片",imgId)
				this.$axios.post("order/opinion/orderOpinionSave", {
					content: this.message,
					imgIds:imgId
				}).then(res => {
					console.log("提交意见反馈", res)
					
					Toast(res.msg)
					if(res.code==1){
						setTimeout(()=>{
							this.loadingBtn=false
							this.$router.go(-1)
						},1000)
					}
					
				})
			},
			//图片上传
			afterRead(file) {
				// 此时可以自行将文件上传至服务器
				console.log("图片",file);
				file.status='uploading'
				file.message="上传中"
				var datas=new FormData()
				datas.append('file',file.file)
				this.$axios.post("img/base/imgUpLoad",datas).then(res=>{
					console.log("文件上传",res)
					if(res.code==1){
						file.status='done'
						this.imgList.push(res.data.url)
						this.imgIdList.push(res.data.id)
					}else{
						file.status='failed'
						file.message="上传失败"
					}
				}).catch(err=>{
					// console.log("错误",err)
					file.status='failed'
					file.message="上传失败"
				})
			},
			//删除图片
			deleteImg(file,detail){
				console.log("删除",file)
				console.log("删除2",detail)
				this.imgList.splice(detail.index,1)
				this.fileList.splice(detail.index,1)
				this.imgIdList.splice(detail.index,1)
			}
		}
	}
</script>

<style>
	.opinionInput .van-cell {
		border-radius: 5px;
	}
</style>
