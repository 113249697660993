<template>
	<div>
		<div>
			<!-- 骨架屏 -->
			<div v-if="showSkeleton">
				<div class="width82 backgroundWhite radius10 margin-top-10" v-for="i in 8" :key="i">
					<van-skeleton title :row="3" />
				</div>

			</div>
			<van-empty v-if="!showSkeleton&&recordList.length<=0" class="custom-image" :image="emptyImg"
				description="您还没有余额使用记录" />
			<div v-if="!showSkeleton&&recordList.length>0">
				<!--  -->
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
						<div class="width82 backgroundWhite radius10 margin-top-10" v-for="(item,index) in recordList"
							:key="index">
							<div class="between" style="padding-bottom: 5px;">
								<div class="text-14">余额使用</div>
								<div class="text-14 blod">￥{{item.profitMoney}}</div>
							</div>
							<div class="text-12" style="color: #A5A6AB;padding-bottom: 3px;">消费订单:{{item.itemCode}}
							</div>
							<div class="text-12" style="color: #A5A6AB;padding-bottom: 3px;">消费明细:{{item.title}}
							</div>
							<div class="text-12" style="color: #A5A6AB;">消费时间:{{item.createDate}}</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "使用记录",
				showSkeleton: true,
				refreshing: false,
				finished: false,
				loading: false,
				param: {
					limit: 10,
					page: 1,
					profitType: 2
				},
				recordList: []
			}
		},
		created() {
			this.getBalanceRecord()
		},
		methods: {
			getBalanceRecord() {
				this.$axios.get("/member/balance/profitBalanceListPage", {
					params: this.param
				}).then(res => {
					console.log("余额使用记录", res)
					this.showSkeleton = false
					this.refreshing=false
					this.loading=false
					if(this.param.page==1){
						this.recordList=res.data
					}else{
						this.recordList=this.recordList.concat(res.data)
					}
					this.total=res.count
					if(this.total<=this.recordList.length){
						this.finished=true
					}
				})
			},
			onRefresh(){
				this.refreshing=true
				this.finished=false
				// this.showSkeleton=true
				this.param.page=1
				// this.recordList=[]
				this.getBalanceRecord()
				
			},
			onLoad(){
				if(this.total<=this.recordList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getBalanceRecord()
			}
		}
	}
</script>

<style>
</style>
