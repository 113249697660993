<template>
	<div>
		<van-form @submit="onSubmit">
			<div class="subscribeForm">
				<van-cell-group>
					<div v-if="userInfo.memberType==1">
						<!-- 商家 -->
						<van-field readonly clickable name="sampleSender" :value="sampleInfo.sampleName" label="送样人"
							right-icon="arrow" placeholder="请选择送样人" @click="toSample"
							:rules="[{ required: true, message: '请选择送样人' }]" />
						<van-field readonly v-model="sampleInfo.tel" name="tel" label="联系电话" placeholder="请输入送样人联系电话"
							:rules="[{ required: true, message: '请输入送样人联系电话' }]" />
					</div>
					<div v-if="userInfo.memberType==2">
						<!-- 员工 -->
						<van-field readonly v-model="userInfo.memberName" name="sampleSender" label="送样人"
							placeholder="请输入送样人姓名" :rules="[{ required: true, message: '请选择送样人' }]" />
						<van-field readonly v-model="userInfo.memberPhone" name="tel" label="联系电话"
							placeholder="请输入送样人联系电话" :rules="[{ pattern, message: '请输入送样人联系电话' }]" />
					</div>
					<div v-if="userInfo.memberType==3">
						<!-- 普通用户 -->
						<van-field v-model="username" name="sampleSender" label="送样人" placeholder="请输入送样人姓名"
							:rules="[{ required: true, message: '请选择送样人' }]" />
						<van-field v-model="phone" name="tel" label="联系电话"  type="number" placeholder="请输入送样人联系电话"
							:rules="[{ pattern, message: '请输入送样人联系电话' }]" />
					</div>

					<van-field readonly clickable name="siteName" :value="site" label="送检站点" right-icon="arrow"
						placeholder="请选择送检站点" @click="toSite" :rules="[{ required: true, message: '请选择送检站点' }]" />
					<!--  -->
				</van-cell-group>
			</div>
			<!--   -->
			<div class="subscribeForm" v-if="inspectionStr=='M'||inspectionStr=='K'||inspectionStr=='Q'||inspectionStr=='H'" >
				<!-- v-if="userInfo.memberMode==1" -->
				<!-- <van-checkbox v-model="checkedPrint" icon-size="14px" @click="clickPrint">
					<span class="text-12 blod">是否打印证书</span>（垫付型客户显示）中卡显示
				</van-checkbox> -->
				<div class="rowCenter text-14" style="padding: 10px 4vw;">
					<div style="color: red;">是否打印证书</div>
					<div class="rowCenter" style="margin-left: 50px;">
						<div class="rowCenter" @click="changePrint(true)">
							<img class="img16" :src="checkedPrint?require('@/assets/images/select.png'):require('@/assets/images/unselect.png')" />
							<div class="margin-left-8">是</div>
						</div>
						
						<div class="rowCenter" style="margin-left: 20px;" @click="changePrint(false)">
							<img class="img16" :src="!checkedPrint?require('@/assets/images/select.png'):require('@/assets/images/unselect.png')" />
							<div class="margin-left-8">否</div>
						</div>
					</div>
				</div>
			</div>
			<div class="subscribeForm number">
				<div class="rowCenter" style="padding: 15px 4vw;">
					<img class="img14" src="../../assets/images/subscribe.png" />
					<div class="text-14 blod" style="margin-left: 5px;">送检信息</div>
				</div>
				<van-cell-group>
					<van-field readonly clickable name="inspectionMarks" :value="inspection" label="检验标识"
						right-icon="arrow" placeholder="请选择" input-align="right"
						:rules="[{ required: true, message: '请选择检验标识' }]" @click="showInspection=true" /><!--  -->

					<van-popup v-model="showInspection" position="bottom">
						<van-picker show-toolbar title="请选择检验标识" value-key="alias" :columns="inspectionList"
							@confirm="onInspection" @cancel="showInspection = false" />
					</van-popup>


					<!-- 珠宝类型的要显示材质 贵金属种类不管是不是珠宝都要显示 -->
					<!-- <van-field readonly clickable name="textureOfMaterial" :value="surfaces" label="类型"
						right-icon="arrow" placeholder="请选择" input-align="right"
						v-if="inspectionStr=='H'||inspectionStr=='M'||inspectionStr=='F'||inspectionStr=='Q'||inspectionStr=='G'"
						 @click="showSurfaces=true" :rules="[{ required: true, message: '请选择材质' }]"/> -->

					<van-popup v-model="showSurfaces" position="bottom">
						<van-picker show-toolbar value-key="setValue" title="请选择类型" :columns="surfacesList"
							@confirm="onSurfaces" @cancel="showPicker = false" />
					</van-popup>


					<!-- <van-field readonly clickable name="nobleMetalType" :value="nobleMetal" label="贵金属种类"
						right-icon="arrow" placeholder="请选择" input-align="right" 
						 @click="showNobleMetal=true" :rules="[{ required: true, message: '请选择贵金属种类' }]"/> -->

					<van-popup v-model="showNobleMetal" position="bottom" >
						<van-picker show-toolbar title="请选择贵金属种类" value-key="setValue" :columns="nobleMetalList"
							@confirm="onNobleMetal" @cancel="showNobleMetal = false" />
					</van-popup>

					<van-field v-model="samples" type="digit" name="sampleNumber" label="样品数" placeholder="请输入数量"
						input-align="right" @blur="countPrice(0)" :rules="[{ required: true, message: '请输入数量' }]" />
					<!-- <van-field v-model="sampleName" name="sampleName" label="样品名称" placeholder="请输入样品名称" v-if="inspectionStr=='A'||inspectionStr=='E'"
						input-align="right" :rules="[{ required: true, message: '请输入样品名称' }]" /> -->
					<!--  -->
					<van-field v-model="sideGold" type="digit" name="goldNumber" label="测金数" placeholder="请输入数量"
						v-if="inspectionStr=='H'||inspectionStr=='M'||inspectionStr=='F'||inspectionStr=='Q'||inspectionStr=='G'"
						input-align="right" @blur="countPrice(1)" />
					<van-field v-model="stones" type="digit" name="stoneNumber" label="测配石数" placeholder="请输入数量"
						v-if="inspectionStr=='H'||inspectionStr=='M'||inspectionStr=='F'||inspectionStr=='Q'||inspectionStr=='G'"
						input-align="right" @blur="countPrice(2)" />
					<!-- <van-field v-model="instruments" type="digit" name="instrumentNumber" label="大型仪器检测数"
						placeholder="请输入数量" input-align="right" @blur="countPrice(3)" v-if="inspectionStr!='A'&&inspectionStr!='E'"/> -->
					<van-field v-model="video" type="digit" name="threeDimensionalVideo" label="三维视频"
						placeholder="请输入数量" input-align="right" @blur="countPrice(4)" v-if="inspectionStr!='A'&&inspectionStr!='E'"/>
					<van-field v-model="other" type="digit" name="otherNumber" label="其他" placeholder="请输入数量"
						input-align="right" @blur="countPrice(5)" v-if="inspectionStr!='A'&&inspectionStr!='E'"/>

					<!--<van-field readonly clickable name="video" :value="video" label="三维视频" right-icon="arrow" placeholder="请选择三维视频" input-align="right"
					  /:rules="[{ required: true, message: '请选择三维视频' }]"  -->


				</van-cell-group>
			</div>
			
			<div class="subscribeForm" v-if="inspectionStr=='A'||inspectionStr=='E'">
				<div class="betweenCenter" style="padding: 15px 4vw;">
					<div class="text-14">取货方式</div>
					<div>
						<van-radio-group v-model="pickUpMode" direction="horizontal" >
						  <van-radio name="1" icon-size="14px" @click="onRadio">到店</van-radio>
						  <van-radio name="2" icon-size="14px" @click="onRadio">物流</van-radio>
						</van-radio-group>
					</div>
				</div>
				<div v-if="pickUpMode==2" @click="toAdress">
					<div class="adress bottomRadius betweenCenter" v-if="adressInfo!=''">
						<div>
							<div class="blod text-14 rowCenter">
								<div>{{adressInfo.contactName}}</div>
								<div style="padding-left: 14px;">{{adressInfo.telephone}}</div>
							</div>
							<div class="text999 text-12" style="padding-top: 5px;">
								{{adressInfo.region}}{{adressInfo.address}}
							</div>
						</div>
						<div class="text-12">
							<div class="rowCenter blue" >
								<div>选择</div>
								<van-icon name="arrow" />
							</div>
						</div>
					</div>
					<div class="text-14 adress" style="text-align: center;" v-if="adressInfo==''">
						<span>亲，请选择本次证书补打的 </span>
						<span class="blue">收货地址</span>
					</div>
				</div>
			</div>

			<div class="subscribeForm ">
				<div class="rowCenter" style="padding: 15px 4vw;">
					<img class="img14" src="../../assets/images/cost.png" />
					<div class="text-14 blod" style="margin-left: 5px;">费用明细</div>
				</div>

				<div class="text-14" style="padding: 0 4vw;">
					<div class="between">
						<div style="color: red;">是否加急</div>
						<div class="urgent">
							<van-checkbox v-model="checkedUrgent" @click="clickUrgent" icon-size="14px"></van-checkbox>
							<div class="lightGray orange">
								￥{{Number(calculationPrice.urgentCost).toFixed(2) }}
							</div>
						</div>
					</div>
					<div class="between padding-bottom-8">
						<div>检测费</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.testingCost).toFixed(2) }}
						</div>
					</div>
					<div class="between padding-bottom-8" v-if="inspectionStr=='H'||inspectionStr=='M'||inspectionStr=='F'||inspectionStr=='Q'||inspectionStr=='G'">
						<div>测金费</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.testGoldCost).toFixed(2) }}
						</div>
					</div>

					<div class="between padding-bottom-8" v-if="inspectionStr=='H'||inspectionStr=='M'||inspectionStr=='F'||inspectionStr=='Q'||inspectionStr=='G'">
						<div>测配石费</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.testStoneCost).toFixed(2) }}
						</div>
					</div>

					<div class="between padding-bottom-8" v-if="inspectionStr!='A'&&inspectionStr!='E'">
						<div>大型仪器检测费</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.testInstrumentCost).toFixed(2) }}
						</div>
					</div>

					<div class="between padding-bottom-8" v-if="inspectionStr!='A'&&inspectionStr!='E'">
						<div>三维视频</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.testThreeDimensionalVideoCost).toFixed(2) }}
						</div>
					</div>
					
					<div class="between padding-bottom-8" v-if="inspectionStr=='M'||inspectionStr=='K'||inspectionStr=='Q'||inspectionStr=='H'|| userInfo.memberMode==2" >
						<div>打印证书</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.printCertificateCost).toFixed(2) }}
						</div>
					</div>
					<div class="between padding-bottom-8 borderBottom" v-if="pickUpMode==2">
						<div>物流</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.logisticsCost).toFixed(2) }}
						</div>
					</div>
					
					<div class="between padding-bottom-8 borderBottom" v-if="inspectionStr!='A'&&inspectionStr!='E'">
						<div>其他</div>
						<div class="lightGray">
							￥{{Number(calculationPrice.otherCost).toFixed(2) }}
						</div>
					</div>
					
					

					<div class="paddingtb8" style="text-align: right;">
						<span>合计预估费用:</span>
						<span class="orange">￥{{Number(totalPrice).toFixed(2) }}</span>
					</div>
				</div>

			</div>

			
			<div style="height: 85px;"></div>


			<!-- 底部按钮 -->
			<div class="bottom">
				<div class="samplingTime" v-if="expectedSamplingDate!=''">
					预计取样时间:{{expectedSamplingDate}}
				</div>
				<div class=" bottomCentent">

					<div class="text-14">
						<span>合计预估费用:</span>
						<span class="orange">￥{{Number(totalPrice).toFixed(2) }}</span>
					</div>
					<van-button :color="colorBtn" type="primary" round :disabled="btnLoading">立即预约</van-button>
				</div>
			</div>


		</van-form>

	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	import {
		bus
	} from "@/bus.js"
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "送检预约",
				backcolor: "#F5F5F5",
				pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
				radio: 0,
				radio2: 0,
				checkedUrgent: false, //是否加急
				checkedPrint: false, //是否打印
				siteInfo: "", //站点
				showSurfaces: false,
				surfacesList: [], //材质
				showNobleMetal: false,
				nobleMetalList: [], //贵金属
				showInspection: false,
				inspectionList: [], //标识
				username: "",
				phone: "",
				site: "",
				sampleName:"",//样品名称
				pickUpMode:"1",//取货方式
				surfaces: "", //材质
				surfacesId: 0,
				nobleMetal: "", //贵金属
				nobleMetalId: 0,
				inspection: "", //检验标识
				inspectionId: 0,
				samples: "", //样品数
				sideGold: "", //测金数
				stones: "", //测配石数
				instruments: "", //大型仪器
				video: "", //三维视频（数量
				other: "", //其他
				authenticationInfo: {},
				userInfo: "",
				sampleInfo: {},
				priceInfo: {}, //查询价格
				calculationPrice: {
					printCertificateCost:0,//打印证书
					testingCost: 0, //检测
					testGoldCost: 0, //测金
					testStoneCost: 0, //测配石
					testInstrumentCost: 0, //大型仪器检测
					testThreeDimensionalVideoCost: 0, //三维视频
					logisticsCost:0,//物流
					otherCost: 0, //其他
					urgentCost: 0 //加急
				}, //计算后价格
				totalPrice: 0, //总价
				freeParam: {
					siteId: ''
				},
				expectedSamplingDate: "请输入样品数", //预计取样时间
				btnLoading: false,
				orderUrgentNum: {}, //订单数量
				testTime: 0 ,//单个检测时间（分钟）
				//查询证书
				certificateCostParam:{
					certificateNumber:1,
				},
				certificateCost:0,
				inspectionMarkId:"",
				inspectionStr:"",//检验标识的首位，用于判断是否展示材质（珠宝类展示）
				
				adressInfo:""//地址

			}
		},
		created() {
			bus.$on('site2', (value) => {
				this.siteInfo = value
				this.site = value.siteName
				console.log("站点b", this.siteInfo)
				this.getOrderUrgent() //根据站点查询订单数量
				//检验标识不为空，查询费用
				this.freeParam.siteId = this.siteInfo.id
				// this.certificateCostParam.siteId=this.siteInfo.id
				// this.getCertificateCost()//查询证书费用
				if(this.freeParam.inspectionMark){
					this.getPrice()
				}
				
			});
			bus.$on('sample', (value) => {
				this.sampleInfo = value
				console.log("送样员", this.sampleInfo)
			});
			
			bus.$on("setAdress",(value)=>{
				console.log("地址a",value)
				this.adressInfo=value
			})
			

		},
		activated() {
			console.log("activated")
			document.body.style.backgroundColor = "#F5F5F5"
			var code = this.$route.params.code
			if(!this.siteInfo||code == 1){//如果站点为空或者从首页进入，
				var site=JSON.parse(localStorage.getItem("siteInfo"))
				if(site.id!=this.siteInfo.id){//如果首页站点和当前页面缓存的站点数据不一样，刷新页面
					Object.assign(this.$data, this.$options.data.call(this)); //重置页面数据
				}
				this.siteInfo=site
				this.site=this.siteInfo.siteName
				this.freeParam.siteId = this.siteInfo.id
				this.getOrderUrgent() //根据站点查询订单数量
				this.certificateCostParam.siteId=this.siteInfo.id
			}
			
			// if (code == 1) {
			// 	var site=JSON.parse(this.$route.params.siteInfo) 
			
			// 	this.siteInfo=site
			// 	this.site=site.siteName
			// 	this.freeParam.siteId = this.siteInfo.id
			// 	this.getOrderUrgent() //根据站点查询订单数量
			// 	this.certificateCostParam.siteId=this.siteInfo.id
			// 	// this.getCertificateCost()//查询证书费用
			// }
			if(!this.userInfo){
				this.getUserInfo()
			}
			// else{
			// 	if(this.userInfo.memberMode==2){
			// 		this.checkedPrint=true
			// 	}
			// }
			if(!this.adressInfo){
				this.getAdress()
			}
			
			this.getSort()//查询分类
			this.getAuthentication()//商家认证
			this.getOrderCheckDate()//查询单个检测时间
			console.log("站点",this.siteInfo)
		},
		methods: {
			changePrint(e){
				this.checkedPrint=e
				this.getPrice()
			},
			//查询最近站点
			getLatelySite() {
				this.$axios.get("/site/base/latelySite", {
					params: this.siteParam
				}).then(res => {
					console.log("最近站点", res)
					this.siteInfo = res.data
					this.site = this.siteInfo.siteName
					this.getOrderUrgent() //根据站点查询订单数量
					//检验标识不为空，查询费用
					this.freeParam.siteId = this.siteInfo.id
					this.getPrice()

				})
			},
			//根据站点查询订单数量
			getOrderUrgent() {
				this.$axios.get("/order/base/getOrderUrgent", {
					params: {
						siteName: this.site
					}
				}).then(res => {
					console.log("站点订单数量", res)
					this.orderUrgentNum = res.data
					if (this.samples > 0) {
						this.computingTime()
					}
				})
			},
			//查询单个检测时间
			getOrderCheckDate() {
				this.$axios.get("/order/base/orderCheckDate").then(res => {
					console.log("检测时间", res)
					this.testTime = res.data
				})
			},
			//计算时间
			computingTime() {
				//预计取样时间计算
				//总时间/8,取余（eg:15/8=1天7小时）
				// console.log("25/8",parseInt(25/8) )取整
				// console.log("25%8",25%8)取余
				
				//获取当前时间戳，和计算后时间相加得出新的时间戳再转换为具体时间
				var timeStamp=new Date().getTime()
				console.log("时间戳",timeStamp)
				var time = 0
				time=timeStamp+2*60*60*1000
				this.expectedSamplingDate =this.formateDate(new Date(time))
				//commonly不加急，urgent加急
				// if (this.testTime != 0) {
				// 	//不加急
				// 	if (!this.checkedUrgent) {
				// 		time = (this.orderUrgentNum.urgent + this.orderUrgentNum.commonly) * this.testTime + (Number(this
				// 			.samples) * this.testTime)
				// 	} else {
				// 		time = this.orderUrgentNum.urgent * this.testTime + Number(this.samples) * this.testTime
				// 	}
				// 	console.log("检测时间", time)
				// 	time=time+120//往后推2小时

				// 	var day=parseInt(time/60/8)//一天工作8小时
				// 	console.log("检测时间day", day)
				// 	var h=parseInt(time/60%8);
				// 	console.log("检测时间h", h)
				// 	var min= parseInt(time % 60);
				// 	console.log("检测时间min", min)
				// 	time=timeStamp+(day*24*60*60*1000+h*60*60*1000+min*60*1000)
				// 	console.log("时间",this.formateDate(new Date(time)))
				// 	this.expectedSamplingDate =this.formateDate(new Date(time))
				// 	// this.expectedSamplingDate = (time < 48) ? time + "小时之后" : (time >= 48 && time < 72) ? "两天之后" : "三天之后"
				// }

			},
			//查询商家认证情况
			getAuthentication() {
				this.$axios.get("business/base/infoBusiness").then(res => {
					console.log("认证详情", res)
					if (res.code == 1) {
						this.authenticationInfo = res.data
					}
				})
			},
			//查询用户信息，判断身份（memberType:1 老板， 2 送样员  3 散户）
			getUserInfo() {
				this.$axios.get("member/base/memberInfo").then(res => {
					console.log("用户信息", res)
					this.userInfo = res.data
					this.sampleInfo.sampleName = this.userInfo.memberName
					this.sampleInfo.tel = this.userInfo.memberPhone
					// if(this.userInfo.memberMode==2){
					// 	this.checkedPrint=true
					// }
				})
			},
			//查询分类
			getSort() {
				this.$axios.get("order/sort/orderConfigureSort", {
					params: this.freeParam
				}).then(res => {
					console.log("分类", res)
					if (res.code == 1) {
						this.inspectionList = res.data.identification //标识
						this.nobleMetalList = res.data.metal //贵金属
						this.surfacesList = res.data.material //材质
					}
				})
			},
			//选择材质
			onSurfaces(e) {
				console.log("材质", e)
				this.surfaces = e.setValue
				this.surfacesId = e.id
				this.showSurfaces = false
			},
			//选择贵金属
			onNobleMetal(e) {
				console.log("贵金属", e)
				this.nobleMetal = e.setValue
				this.nobleMetalId = e.id
				this.showNobleMetal = false
			},
			//选择检验标识
			onInspection(e) {
				console.log("检验标识", e)
				this.inspection = e.alias
				this.inspectionStr=e.setValue.substr(0,1)
				this.inspectionId = e.id
				this.showInspection = false
				this.freeParam.inspectionMark = e.id
				
				
				if(this.inspectionStr=='M'||this.inspectionStr=='K'||this.inspectionStr=='Q'||this.inspectionStr=='H'){
					this.checkedPrint=true
				}else{
					this.checkedPrint=false
				}
				//站点不为空，查询费用
				if (this.freeParam.siteId) {
					this.getPrice()
				}
			},
			//选择取货方式
			onRadio(e){
				console.log("选择取货方式",e)
				this.getPrice()
			},
			//查询费用
			getPrice() {
				var freeParam=this.freeParam
				
				if (this.checkedPrint ) { //是否打印证书 现付型商家默认打印|| this.userInfo.memberMode==2
					freeParam.printCertificate = 1
				} else {
					freeParam.printCertificate = 0
				}
				if(this.inspectionStr=='A'||this.inspectionStr=='E'){
					freeParam.pickUpMode=this.pickUpMode
				}else{
					delete freeParam.pickUpMode
				}
				
				if (this.checkedUrgent) { //是否加急
					freeParam.urgent = 1
				} else {
					freeParam.urgent = 0
				}
				freeParam.sampleNumber=(!this.samples)?0:this.samples//样品
				
				freeParam.goldNumber=(!this.sideGold)?0:this.sideGold//侧金数
				// freeParam.instrumentNumber=(!this.instruments)?0:this.instruments//大型仪器检测数
				freeParam.otherNumber=(!this.other)?0:this.other//其他
				freeParam.stoneNumber=(!this.stones)?0:this.stones//测配石数
				freeParam.threeDimensionalVideo = (!this.video)?0:this.video//三维视频
				console.log("查询费用",freeParam)
				this.$axios.get("/order/cost/orderTotalCost", {
					params: this.freeParam
				}).then(res => {
					console.log("价格", res)
					if (res.code == 1) {
						this.calculationPrice=res.data
						this.totalPrice = res.data.actualTotalCost
					} else {
						Toast('暂无价格信息');
						this.totalPrice =0
					}
					
				})

			},
			//查询证书费用
			getCertificateCost(){
				console.log("证书费用param",this.certificateCostParam)
				this.$axios.get("/certificate/certificateOrder/certificateCost",{
					params:this.certificateCostParam
				}).then(res=>{
					console.log("证书费用",res)
					this.certificateCost=res.data
				})
			},
			//垫付型客户打印证书
			clickPrint(){
				console.log("是否打印证书",this.checkedPrint)
				this.getPrice()
				// this.calculation()
			},
			//计算价格
			calculation() {
				if (this.checkedUrgent && JSON.stringify(this.priceInfo) != "{}") { //加急费用
					this.calculationPrice.urgentCost = (this.priceInfo.urgentCost).toFixed(2)
				} else {
					this.calculationPrice.urgentCost = 0
				}
				//是否打印证书 (现付型商家打印证书费用包含在检测样品费用中)
				if(this.checkedPrint && JSON.stringify(this.priceInfo) != "{}"){
					this.calculationPrice.certificateCost=(this.priceInfo.mailCostMat* Number(this.samples)).toFixed(2)
				}else{
					this.calculationPrice.certificateCost=0
				}
				
				var totalPrice =0
				if(this.userInfo.memberMode==1){
					//如果是垫付型商家 
					//测金数*测金费 + 测配石数*测配石费 + 三维视频数*三维视频检测费  +  大型仪器检测数*检测费 + 其他数*其他费用 + 加急费用 + 样品数*证书补打费用（垫付）
					totalPrice =Number(this.calculationPrice.testGoldCost) +
						Number(this.calculationPrice.testStoneCost) +Number(this.calculationPrice.testInstrumentCost) +
						Number(this.calculationPrice.testThreeDimensionalVideoCost) + Number(this.calculationPrice.testOtherCost)
						+ Number(this.calculationPrice.urgentCost) +Number(this.calculationPrice.certificateCost)
				}else{
					//现付型商家（检测费包含了打印证书费用）
					//样品数*检测费用 + 测金数*测金费 + 测配石数*测配石费 + 三维视频数*三维视频检测费  +  大型仪器检测数*检测费 + 其他数*其他费用 + 加急费用
					totalPrice = Number(this.calculationPrice.testCost) + Number(this.calculationPrice.testGoldCost) +
						Number(this.calculationPrice.testStoneCost) +
						Number(this.calculationPrice.testInstrumentCost) +
						Number(this.calculationPrice.testThreeDimensionalVideoCost) + Number(this.calculationPrice
							.testOtherCost) + Number(this.calculationPrice.urgentCost) 
				}
	
				this.totalPrice = totalPrice.toFixed(2)
			},
			//输入数量计算价格
			countPrice(index) {
				console.log("********", JSON.stringify(this.priceInfo) != "{}")
				if (index == 0) {
					this.computingTime() //计算取件时间
				}
				// if (JSON.stringify(this.priceInfo) != "{}") {
				// 	if(this.userInfo.memberMode==2||this.userInfo.memberMode==3){//现付型商家
				// 		this.calculationPrice.testCost = (this.priceInfo.testCost * Number(this.samples)).toFixed(2) //检测费
				// 	}
					
				// 	this.calculationPrice.testGoldCost = (this.priceInfo.testGoldCost * Number(this.sideGold)).toFixed(
				// 		2) //测金费
				// 	this.calculationPrice.testStoneCost = (this.priceInfo.testStoneCost * Number(this.stones)).toFixed(
				// 		2) //测配石费
				// 	this.calculationPrice.testInstrumentCost = (this.priceInfo.testInstrumentCost * Number(this
				// 		.instruments)).toFixed(2) //大型仪器检测器
				// 	this.calculationPrice.testThreeDimensionalVideoCost = (this.priceInfo.testThreeDimensionalVideoCost *
				// 		Number(this.video)).toFixed(2) //三维视频
				// 	this.calculationPrice.testOtherCost = (this.priceInfo.testOtherCost * Number(this.other)).toFixed(
				// 		2) //其他费用
				// }

				// console.log("*****", this.calculationPrice)
				
				this.getPrice()
				
				// this.calculation()
			},
			//加急
			clickUrgent() {
				// var checkedUrgent=!this.checkedUrgent
				// console.log("*****",checkedUrgent)
				//计算价格
				this.getPrice()
				//计算时间
				this.computingTime()
			},


			//提交预约
			onSubmit(value) {
				if(this.totalPrice<=0&&value.sampleNumber<1){
					Toast("此站点无价格信息！")
					return;
				}
				console.log("pickUpMode",this.pickUpMode)
				if(this.pickUpMode==2){
					if(!this.adressInfo){
						Toast("请选择地址")
						return;
					}
					value.addressId=this.adressInfo.id
				}
				this.btnLoading = true
				if(this.inspectionStr=='M'||this.inspectionStr=='K'||this.inspectionStr=='Q'||this.inspectionStr=='H'){
					if (this.checkedPrint ) { //是否打印证书 中卡可选择，大卡小卡默认打印，报告无
						value.printCertificate = 1
					} else {
						value.printCertificate = 0
					}
				}else if(this.inspectionStr=='N'||this.inspectionStr=='S'||this.inspectionStr=='G'||this.inspectionStr=='F'){
					value.printCertificate = 1
				}else{
					delete value.printCertificate
				}
				
				if (this.checkedUrgent) { //是否加急
					value.urgent = 1
				} else {
					value.urgent = 0
				}
				value.pickUpMode=this.pickUpMode
				
				value.siteId=this.siteInfo.id
				value.inspectionMark=this.inspectionId
				value.goldNumber=(!value.goldNumber)?0:value.goldNumber
				value.instrumentNumber=(!value.instrumentNumber)?0:value.instrumentNumber
				value.otherNumber=(!value.otherNumber)?0:value.otherNumber
				value.stoneNumber=(!value.stoneNumber)?0:value.stoneNumber
				value.goldNumber=(!value.goldNumber)?0:value.goldNumber
				value.threeDimensionalVideo = (!value.threeDimensionalVideo)?0:value.threeDimensionalVideo
				// value.expectedSamplingDate=this.expectedSamplingDate//预计取样时间
				// value.threeDimensionalVideo = this.threeDimensionalVideo
				console.log("提交", value)
				
				this.$axios.post("/order/base/createOrder", value).then(res => {
					console.log("提交预约订单", res)
					if (res.code == 1) {
						
						Dialog.alert({
								title: '预约提示',
								message: '已成功预约此次送检订单！',
								confirmButtonColor: this.defaultColor,
							})
							.then(() => {
								// on confirm
								Object.assign(this.$data, this.$options.data.call(this)); //重置页面数据
								this.$router.replace({
									path: "/submissionOrder",
									query: {
										state: 0
									}
								})
								
							})
							.catch(() => {
								this.btnLoading = false
								// on cancel
							});
						
						
					} else {
						Toast(res.msg)
					}
					this.btnLoading = false
				}).catch(err => {
					this.btnLoading = false
					Toast("提交失败")
				})
				
				
			},
			//跳转选择站点
			toSite() {
				this.$router.push({
					path: "/site",
					query:{
						code:2
					}
				})
			},
			//跳转送样员
			toSample() {
				this.$router.push({
					path: "/sample"
				})
			},
			//查询默认地址
			getAdress(){
				this.$axios.get("/certificate/certificateOrder/defaultAddress").then(res=>{
					console.log("默认地址",res)
					if(res.data && res.data!="没有默认收货地址！"){
						this.adressInfo=res.data
					}
				})
			},
			//选择地址
			toAdress(){
				this.$router.push({
					path:"/adress",
					query:{
						adressState:1
					}
				})
			},

		}
	}
</script>

<style>
	.van-cell-group {
		border-radius: 10px;
	}

	.subscribeForm .van-cell {
		border-radius: 10px;
		padding: 10px 4vw;
	}

	.subscribeForm {
		width: 90vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		margin-top: 15px;
	}

	.number .van-field__label {
		width: auto !important;
	}

	.number .van-field__error-message {
		text-align: right;
	}

	.samplingTime {
		width: 90vw;
		padding: 7px 5vw;
		background: #EDF8FF;
		color: #0080FD;
		font-size: 12px;

	}

	.bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

	}

	.bottomCentent {
		display: flex;
		flex-direction: row;
		padding: 5px 5vw;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
	}

	.urgent {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
</style>
