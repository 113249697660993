<template>
	<div>
		
		<div style="width: 88px;height: 88px;border-radius: 88px;margin: auto;margin-top: 50px;">
			<img style="width: 88px;height: 88px;border-radius: 88px;margin: auto;" src="../assets/images/login.png" />
		</div>
		<div class="width90 borderBottom" style="margin: 30px auto;"></div>
		<div class="width90">
			<div class="text-16 blod">质检体统申请获取以下权限：</div>
			<div style="color: #B6B6B6;padding-top: 10px;padding-bottom: 40px;" class="text-16 blod">获得你的公开信息（昵称、头像等）</div>
			<van-button @click="login" style="width: 90vw;" :color="colorBtn" type="primary" round>授权登录</van-button>
			<!-- <van-button @click="clickShow" style="width: 90vw;" :color="colorBtn" type="primary" round>授权登录</van-button> -->
			<div style="padding-top: 20px;" class="rowCenter">
				<van-checkbox v-model="checkAgreement" icon-size="14px">
					
				</van-checkbox>
				<span class="text-14 margin-left-8">同意<span class="blue" @click.stop="toAgreement">用户协议</span></span>
			</div>
		</div>
		<van-popup v-model="showMemberId" round >
			<div style="width: 70vw;height: 50vw;display: flex;flex-direction: column;align-items: center;">
				
				<van-field v-model="code" label="code" placeholder="请输入code" />
				<van-button style="width: 30vw;" @click="login" :color="colorBtn" type="primary" round>登录</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "登录",
				backcolor: "#FFFFFF",
				checkAgreement:false,
				showMemberId:false,
				code:"",
				// memberId:"10000001"
			}
		},
		created() {
			// var urlPath="https://www.ebaoku.com/#/"
			// setTimeout(()=>{
			// 	history.replaceState({ title: '' }, '', "https://localhost:8081/#/");//正式
			// },2000)
			this.checkAgreement=localStorage.getItem("checkAgreement")
			
			this.code=this.getUrlParam("code")
			console.log("code",this.code)
			
			//scope=snsapi_base或scope=snsapi_userinfo
			// let redirect_uri = encodeURIComponent(window.location.href)
			
			// window.location.href =
			// 	"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf04a216c8e6cfe3&redirect_uri=https%3A%2F%2Fwww.ebaoku.com%2F%23%2Flogin" + redirect_uri +
			// 	"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
						
		},
		methods: {
			clickShow(){
				this.showMemberId=true
				
			},
			login(){
				if(!this.checkAgreement){
					
					Toast("请同意用户协议！")
					return;
				}
				localStorage.setItem("checkAgreement",this.checkAgreement)
				// var redirect_uri=encodeURIComponent("https://www.ebaoku.com/#/login")
				// var urlPath=
				// 	"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf04a216c8e6cfe3&redirect_uri=" + redirect_uri +
				// 	"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
				// console.log("url",urlPath)	
				
				// history.replaceState({ title: '' }, '', urlPath);//正式
				
				// this.code=this.getUrlParam("code")
				// console.log("code",this.code)
				
				//授权登录
				this.$axios.post("/member/base/openIdAndSessionKey",{code:this.code}).then(res=>{
					console.log("登录",res)
						if(res.code==1){
							localStorage.setItem('token',res.data);
							// this.$router.go(-1)
							this.$router.back()
						}else{
							Toast(res.msg)
						}
				})
			},
			toAgreement(){
				this.$router.push({
					path:"/agreement",
					query:{
						title:"用户协议",
						state:3
					}
				})
			}
		}
	}
</script>

<style>
</style>
