<template>
	<div>
		<!-- 个人信息 -->
		<div class="personalInfo">
			<div class="rowCenter" style="margin-left: 5vw;padding-top: 40px;">
				<div class="headPortrait">
					<img class="headImg" :src="userInfo.avatarUrl" />
					<div class="grade" v-if="userInfo.vipState==1">
						<img style="width: 18px;height: 18px;" src="../../assets/images/vip.png" />
					</div>
				</div>
				<div class="margin-left-10 white">
					<div class="rowCenter">
						<div class="text-16">{{userInfo.nickName}}</div>
						<!-- 0未认证，2审核中，6已认证，7审核失败 -->
						<img style="width: 45px;height: 16px;" :src="userInfo.businessState==6?require('@/assets/images/authentication.png'):require('@/assets/images/unauthorized.png')" />
					</div>
					<div class="text-14 line-height17">ID:{{userInfo.id}}</div>
					<div class="text-14 line-height17">电话:{{userInfo.memberPhone==""?"请完善信息":userInfo.memberPhone}}</div>
				</div>
			</div>

			<div v-if="userInfo.memberType!=2" class="businessCard" @click="toAuthentication">
				商家认证
			</div>
		</div>
		<!-- 送检订单 -->
		<div class="inspectOrder" style="margin-top: -50px;">
			<div class="text-14">
				送检订单
			</div>
			<div class="nav wrap">
				<div v-for="(item,index) in navList" :key="index" class="center navList " @click="toSubmissionOrder(item)">
					<van-badge :content="item.number>99?'99+':item.number" v-if="item.number>0">
						<img style="width: 30px;height: 30px;margin: auto;" :src="item.image" />
					</van-badge>
					<img v-else style="width: 30px;height: 30px;margin: auto;" :src="item.image" />
					<div class="blod">{{item.name}}</div>
				</div>
			</div>
		</div>

		<!-- 广告 -->
		<div v-if="userInfo.memberType==1" class="width90 margin-top-10 radius5" style="height: 21.3vw;position: relative;"><!--  @click="showQRcode=true" -->
			<img style="width: 90vw;height: 21.3vw;" src="../../assets/images/advertisement.png" />
			<div class="content">
				<div class="text-14 blod">上找货平台 挑选更多好货</div>
				<div class="text-12 blod">
					<span style="color: #A5A6AB;margin-right: 5px;">宝库电商系统</span>
					<span class="yellow">点我去看</span>
				</div>
			</div>
		</div>


		<!-- 我的财产 -->
		<div class="inspectOrder margin-top-10">
			<div  class="text-14">
				我的财产
			</div>
			<div class="nav between">
				<div v-for="(item,index) in propertyList" :key="index" class="center" @click="skip(item.url)">
					<img style="width: 30px;height: 30px;margin: auto;" :src="item.image" />
					<div class="blod">{{item.name}}</div>
				</div>
			</div>
		</div>

		<!-- 设置 -->
		<div class="myInfo">
			<van-cell-group inset>
				<!-- <van-cell @click="toPackageRecord" :icon="require('@/assets/images/mealOrder.png')" title="套餐购买记录" is-link /> -->
				<van-cell @click="toMyCertificateOrder" :icon="require('@/assets/images/certificateOrder.png')" title="证书补打订单" is-link />
				<van-cell @click="toSetUp" :icon="require('@/assets/images/seUp.png')" title="更多设置" is-link />
			</van-cell-group>
		</div>
		<!-- <div style="width: 90vw;margin: auto;margin-top: 10px;">
			<van-button @click="logOut" style="width: 90vw;margin: auto;" :color="colorBtn" type="primary" round>退出登录</van-button>
		</div> -->
		
		<!-- 二维码 -->
		<van-popup v-model="showQRcode" closeable round >
			<div style="width: 80vw;padding-top: 14px;text-align: center;">
				<div>宝库电商系统</div>
				<div style="width: 64.5vw;height: 64.5vw;margin: auto; padding: 35px 0;">
					<img  style="width: 64.5vw;height: 64.5vw;margin: auto;" src="../../assets/images/lunbo.png" />
				</div>
			</div>
		</van-popup>

		<tabbar></tabbar>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import Tabbar from "@/components/tabbar.vue"
import { Toast } from "vant"
	export default {
		extends: BaseView,
		components: {
			Tabbar
		},
		data() {
			return {
				title: "我的",
				showQRcode:false,
				userInfo:{},
				//送检订单
				navList: [{
						image: require("@/assets/images/myNav/nav1.png"),
						name: "待确认",
						state: 0,
						number: 0
					},
					{
						image: require("@/assets/images/myNav/nav2.png"),
						name: "检测中",
						state: 1,
						number: 0
					},
					{
						image: require("@/assets/images/myNav/nav3.png"),
						name: "待付款",
						state: 2,
						number: 0
					},
					{
						image: require("@/assets/images/myNav/nav4.png"),
						name: "待取样",
						state: 3,
						number: 0
					},
					{
						image: require("@/assets/images/myNav/nav5.png"),
						name: "已完成",
						state: 4,
						number: 0
					},
					{
						image: require("@/assets/images/myNav/nav6.png"),
						name: "已取消",
						state: 5,
						number: 0
					}

				],
				propertyList: [{
						image: require("@/assets/images/property/property1.png"),
						name: "余额",
						state: 0,
						url:"/balance"
					},
					{
						image: require("@/assets/images/property/property2.png"),
						name: "积分",
						state: 1,
						url:"/integral"
					},
					// {
					// 	image: require("@/assets/images/property/property3.png"),
					// 	name: "检验次数",
					// 	state: 2,
					// 	url:"/quality"
					// },
					{
						image: require("@/assets/images/property/property4.png"),
						name: "月结",
						state: 3,
						url:"/monthlyStatementRecord"
					},
					{
						image: require("@/assets/images/property/property5.png"),
						name: "记账",
						state: 4,
						url:"/bookkeeping"
					}
				]
			}
		},
		created() {
			// this.getBalance()
			this.getUserInfo()
		},
		methods: {
			getBalance() {
				this.$axios.get("/member/base/payUsable").then(res => {
					console.log("余额等", res)
					this.balance = res.data.balance //余额
					this.integral = res.data.integral //积分
					this.frequency = res.data.number //次数
				})
			},
			//退出登录
			logOut(){
				this.$router.push({
					path: '/login',
				})
			},
			//查询用户信息
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					console.log("用户信息",res)
					this.getSubmissionOrderCount()
					this.userInfo=res.data
					if(this.userInfo.avatarId){
						this.userInfo.avatarUrl=this.imageUrl+this.userInfo.avatarUrl
						console.log("******",this.userInfo.avatar)
					}
					
				})
			},
			//查询订单统计
			getSubmissionOrderCount(){
				this.$axios.get("/order/base/getOrderNumber").then(res=>{
					console.log("订单统计",res)
					this.navList[0].number=res.data.waitConfirm
					this.navList[1].number=res.data.testing
					this.navList[2].number=res.data.waitPayment
					this.navList[3].number=res.data.waitSampling
					// this.navList[4].number=res.data.finish
					// this.navList[5].number=res.data.cancel
				})
			},
			toSetUp(){
				this.$router.push({
					path:"/setUp"
				})
			},
			toAuthentication(){
				this.$router.push({
					path:"/authentication"
				})
			},
			toSubmissionOrder(item){
				this.$router.push({
					path:"/submissionOrder",
					query:{
						state:item.state
					}
				})
			},
			//我的财产跳转
			skip(url){
				if(this.userInfo.memberType==2){
					//送样员
					Toast("请登录BOSS账号查看！")
					return;
				}
				this.$router.push({
					path:url
				})
			},
			//套餐记录
			toPackageRecord(){
				this.$router.push({
					path:"/packageRecord"
				})
			},
			//补打证书订单
			toMyCertificateOrder(){
				this.$router.push({
					path:"/myCertificateOrder"
				})
			}
		}
	}
</script>

<style>
	.personalInfo {
		width: 100vw;
		/* height: 40vw; */
		height: 170px;
		background-image: url(../../assets/images/prsonal.png);
		background-size: 100% 100%;
	}

	.headPortrait {
		width: 60px;
		height: 60px;
		background: #CCCCCC;
		border-radius: 60px;

		position: relative;
	}

	.headImg {
		width: 60px;
		height: 60px;
		border-radius: 60px;
	}

	.grade {
		position: absolute;
		bottom: -6px;
		right: -6px;
		width: 22.6px;
		height: 21.8px;
	}

	.businessCard {
		position: absolute;
		top: 55px;
		right: 0;
		width: 60px;
		height: 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		background: #FFEFD8;
		color: #BB4B0C;
		line-height: 30px;
		font-size: 12px;
		text-align: center;
	}

	/* 送检订单 */
	.inspectOrder {
		width: 82vw;
		padding: 12px 4vw;
		background: #FFFFFF;
		margin: auto;
		margin-top: 10px;
		border-radius: 10px;

	}

	.nav {
		border-radius: 10px;
		margin: auto;
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		background: #FFFFFF;
		font-size: 12px;
	}

	/* 广告文字 */
	.content {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 4vw;
		height: 35px;
		margin: auto;
	}

	.myInfo {
		width: 90vw;
		margin: auto;
		margin-top: 10px;
		background: #FFF;
		border-radius: 10px;
	}

	.myInfo .van-cell-group--inset {
		margin: 0;
		border-radius: 10px;
	}
	.myInfo .van-cell{
		padding: 10px 4vw;
	}
	
	.navList{
		width: 25%;
		margin-bottom: 10px;
	}
</style>