<template>
	<div>
		<van-tabs v-model="state" swipeable color="#0080FD" sticky @change="changeTabs">
			<van-tab v-for="(tab,key) in tabList" :key="key" :title="tab">
				<!-- 骨架屏 -->
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-top-10" v-for="i in 6" :key="i">
						<van-skeleton :row="2" />
						<div class="borderBottom" style="margin: 10px 0;"></div>
						<van-skeleton avatar avatar-shape="square" avatar-size="80px" :row="4" />
						<div class="borderBottom" style="margin: 10px 0;"></div>
						<van-skeleton :row="1" />
					</div>
				</div>

				<van-empty v-if="!showSkeleton&&orderList.length<=0" class="custom-image" :image="emptyImg"
					description="您还没有证书补打订单" />
				<div v-if="!showSkeleton&&orderList.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
							<div class="width82 backgroundWhite radius10 margin-top-10"
								v-for="(item,index) in orderList" :key="index" @click="toDetail(item)">
								<div class="text-12 borderBottom padding-bottom-10">
									<div class="between">
										<div>订单:{{item.orderCode}}</div>
										<div class="text-14">
											{{tabList[item.state]}}
											<!-- {{state==0?'待确认':state==1?'待发货':state==2?'待收货':state==3?'已完成':'已取消'}} -->
										</div>
									</div>
									<div class="between">
										<div>创建:{{item.createDate}}</div>
										<div class="blue rowCenter">
											<div>{{item.printMode==1?"门店":item.printMode==2?"线下":""}}</div>
											<div v-if="item.printMode==1">{{item.receiveType==2?"邮寄":item.receiveType==1?"自提":""}}</div>
										</div>
										
									</div>
								</div>
								<div class="borderBottom padding-top-10">
									<div v-if="item.receiveType==1&&item.printMode==1" class="text-14 blue padding-bottom-8">
										自提时间:{{item.receiveTime}}</div>
									<div v-for="(list,key) in item.certificateJson" :key="key"
										class="rowCenter padding-bottom-10">
										<div>
											<van-image fit="cover" class="img80" :src="list.certificateImgUrl"></van-image>
										</div>
										<div class="text-12 margin-left-10">
											<div>证书编号:{{list.certificateNumber}}</div>
											<div class="padding-top-5">鉴定结果:{{list.checkResult}}</div>
											<div class="padding-top-5">总质量:{{list.totalMass}}</div>
											<div class="padding-top-5">颜色:{{list.color}}</div>
											<div class="padding-top-5">数量:{{list.num}}</div>
										</div>
									</div>
								</div>
								<div class="betweenCenter padding-top-10">
									<div class="text-12">
										<span>共</span>
										<span class="orange">{{item.certificateNumber}}</span>
										<span>件，合计费用:</span>
										<span class="orange blod">￥{{item.cost}}</span>
									</div>
									<div class="flex-row">
										<div v-if="item.state==0" class="strokeBtn margin-right-10"
											@click.stop="clickCancel(item)">取消订单
										</div>
										<div  :class="[item.state==2?'strokeBtn':'solidBtn']">查看订单
										</div>
										<div v-if="item.state==2" class="solidBtn margin-left-10"
											@click.stop="clickReceiving(item)">
											确认收货</div>
									</div>

								</div>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>
			</van-tab>
		</van-tabs>

	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { Dialog } from 'vant';
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "证书补打",
				tabList: ["待确认", "待发货", "待收货", "已完成", "已取消"],
				refreshing: false,
				finished: false,
				loading: false,
				showSkeleton: true,
				showTips: false,
				showReceiving: false,
				state: 0,
				param: {
					page: 1,
					limit: 10,
					state: 0 //0 待确认 1 待发货 2 待收货 3 已完成 4 已取消
				},
				orderList: [],
				total: 0
			}
		},
		created() {
			this.getOrderList()
		},
		computed: {
			totalSumAll() {
				return function(certificateNums) {
					
					let totalSumAll = 0;
					var arr=certificateNums.split(",")
					arr.map((item) => {
						totalSumAll += Number(item) 
					})
					return totalSumAll
				};
			},
		},
		methods: {
			//查询订单
			getOrderList() {
				console.log("订单param", this.param)
				this.$axios.get("/certificate/certificateOrder/certificateOrderListPage", {
					params: this.param
				}).then(res => {
					console.log("补打证书订单列表", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if (this.param.page == 1) {
						this.orderList = res.data
					} else {
						this.orderList = this.orderList.concat(res.data)
					}
					this.total = res.count
					if(this.total<=this.orderList.length){
						this.finished=true
					}
				})
			},
			//切换tab
			changeTabs(e) {
				console.log("切管tabs", e)
				this.state = e
				this.param.state = e
				this.param.page = 1
				this.showSkeleton=true
				this.getOrderList()
			},
			//取消订单
			clickCancel(item) {
				Dialog.confirm({
					title: '取消提示',
					message: '确定取消本次证书补打?',
					confirmButtonColor: this.defaultColor
				})
				.then(() => {
					// on confirm
					this.$axios.get("/certificate/certificateOrder/cancelOrder",{
						params:{
							orderId:item.id
						}
					}).then(res=>{
						console.log("取消订单",res)
						Toast(res.msg)
						this.onRefresh()
					})
				})
				.catch(() => {
					// on cancel
				});
			},
			//收货
			clickReceiving(item) {
				Dialog.confirm({
					title: '确定收货',
					message: '确定收货?',
					confirmButtonColor: "#0080FD"
				})
				.then(() => {
					// on confirm
					this.$axios.get("/certificate/certificateOrder/confirmOrder",{
						params:{
							orderId:item.id
						}
					}).then(res=>{
						console.log("确定收货",res)
						Toast(res.msg)
						this.onRefresh()
					})
				})
				.catch(() => {
					// on cancel
				});

			},
			//跳转详情
			toDetail(item) {
				this.$router.push({
					path: "/certificateOrderDetail",
					query: {
						state: this.state,
						orderId:item.id
					}
				})
			},
			onRefresh() {
				this.finished = false
				this.refreshing=true
				// this.showSkeleton=true
				this.param.page = 1
				this.getOrderList()
			},
			onLoad() {
				if(this.total<=this.orderList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getOrderList()
			}
		}
	}

</script>

<style>
</style>
