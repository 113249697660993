<template>
	<div>
		<div class="indexCell">
			<!--定位最近站点  -->
			<van-cell @click="toSite" :title="siteName" is-link value="切换"
				:icon="require('@/assets/images/location.png')" />
		</div>

		<!-- <site></site> -->

		<!-- 轮播 -->
		<div class="swipe">
			<van-swipe class="swipe" :autoplay="3000">
				<van-swipe-item v-for="(image, index) in swipeImg" :key="index">
					<van-image fit="cover" class="swipe-img" :src="image.url" ></van-image>
				</van-swipe-item>
			</van-swipe>
		</div>

		<!-- 预约/套餐 -->
		<div class="width90 between margin-top-10">
			<img @click="toSubscribe" class="indexImg" src="../../assets/images/appointment.png" />
			<!-- <img @click="toSetMeal" class="indexImg" src="../../assets/images/setMeal.png" /> -->
			<img @click="toSetMeal" class="indexImg" src="../../assets/images/yuechongzhi.png" />
		</div>

		<!-- 公司介绍 -->
		<div class="width90" style="margin-top: 25px;">
			<div class="rowCenter">
				<img style="width: 7.4vw;height: 3.2vw;" src="../../assets/images/company.png" />
				<div>公司介绍</div>
			</div>
			<div class="margin-top-15 introduceInfo" v-html="introduceInfo.details">
				<!-- <van-image  fit="cover" class="companyImg" :src="require('@/assets/images/lunbo.png')"></van-image>
				<div class="margin-top-10">
					这里是国家珠宝玉石质量监督检验中心（简称检验中心）是由国家有关主管部门依法授权的国家级珠宝玉石专业质检机构，是中国珠宝玉石检测方面的权威。
					
					检验中心面向社会提供委托检验服务，承担国家的市场监督检验、仲裁检验、进出口商品检验，制定、修订相关的国家标准等多项任务。在规范国内珠宝市场，促进珠宝行业健康发展方面起着指导作用。
				</div> -->
			</div>
		</div>

		<tabbar></tabbar>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import Tabbar from "@/components/tabbar.vue"
	import {
		bus
	} from "@/bus.js"
	import {
		Toast
	} from 'vant';
	import wx from 'weixin-js-sdk'
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		components: {
			Tabbar
		},
		data() {
			return {
				title: "首页",
				backcolor: "#FFFFFF",
				siteName: "定位最近站点",
				siteInfo: "",
				introduceInfo: {},
				siteParam:{},//最近站点
				swipeImg: [] /*, require("@/assets/images/lunbo.png"),require("@/assets/images/lunbo.png") */
			}
		},
		created() {
			console.log("created")
			if(this.init_wx){
				// var url="https://www.ebaoku.com/#/login"
				var url = "https://zhijiandb.cugi.cn/#/login"
				// var url=window.location.href
				this.$axios.post("wx/h5Pay/wxH5Config?url="+url).then(res=>{
					console.log("微信配置", res)
					this.init_wx=false
					if(res.code==1){
						wx.config({
						    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						    appId: res.data.appId, // 必填，公众号的唯一标识
						    timestamp:  res.data.timestamp, // 必填，生成签名的时间戳
						    nonceStr:  res.data.nonceStr, // 必填，生成签名的随机串
						    signature:  res.data.signature, // 必填，签名
						    jsApiList: ['scanQRCode','getLocation'] // 必填，需要使用的JS接口列表
						});
						
					}else{
						Toast(res.msg)
					}
				})
			}
			// console.log("序列化地址",encodeURIComponent("https://www.ebaoku.com/#/login"))
			let that = this
			// bus.$on('site',(name)=>{
			// 	this.siteName=name
			// 	console.log("站点",this.siteName)
			// })
			// console.log("站点2",this.siteName)
			bus.$on('site1', function(value) {
				that.siteName = value.siteName
				that.siteInfo = value
				localStorage.setItem("siteInfo",JSON.stringify(that.siteInfo))
				console.log("站点a", that.siteName)
			});
			// this.getLatelySite()
			this.getSwipe()
			this.getIntroduce()
			// this.getLocation()//获取经纬度
			
		},
		activated() {
			document.body.style.backgroundColor = "#FFFFFF"
			console.log("activated")
			
			if (!this.siteInfo) {
				this.getLocation()//获取经纬度
				// this.getLatelySite()
			}
		},
		methods: {
			//获取经纬度
			getLocation(){
				var that=this
				wx.checkJsApi({
					jsApiList: [
						'getLocation'
					],
					success: function(res) {
						console.log("是否支持",res)
						// alert(JSON.stringify(res));
						// alert(JSON.stringify(res.checkResult.getLocation));
						if (res.checkResult.getLocation == false) {
							alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
							return;
						}
					}
				});
				wx.ready(function () {
					wx.getLocation({
						success: function(res) {
							console.log("定位", res);
							localStorage.setItem("longAlat",JSON.stringify(res))
							that.getLatelySite()
							
							// this.longitude=res.longitude//经度
							// this.latitude=res.latitude//纬度
						},
						cancel: function(res) {
							console.log('用户拒绝授权获取地理位置', res);
						}
					});
				});
				wx.error(function(res){
					console.log("错误",res)
				})
			},
			//查询最近站点
			getLatelySite() {
				var longAlat=JSON.parse(localStorage.getItem("longAlat"))
				this.$axios.get("/site/base/latelySite", {
					params: {
						memberLatitude:longAlat.latitude,
						memberLongitude:longAlat.longitude
					}
				}).then(res => {
					console.log("最近站点", res)
					this.siteInfo = res.data
					this.siteName = this.siteInfo.siteName
					localStorage.setItem("siteInfo",JSON.stringify(this.siteInfo))

				})
			},
			//查询公司介绍
			getIntroduce() {
				this.$axios.get("text/base/textInfo", {
					params: {
						serverType: 1
					}
				}).then(res => {
					console.log("公司介绍", res)
					this.introduceInfo = res.data
				})
			},
			//查询轮播
			getSwipe() {
				this.$axios.get("img/turn/turnImgList", {}).then(res => {
					console.log("轮播", res)
					if (res.code == 1) {
						this.swipeImg = res.data
						console.log("img", this.swipeImg)
						// this.swipeImg=res.data.data
					}
				})
			},
			toSite() {
				this.$router.push({
					path: "/site",
					query: {
						code: 1
					}
				})
			},
			//跳转预约
			toSubscribe() {
				this.$axios.get("member/base/memberInfo").then(res => {
					console.log("用户信息", res)
					var userInfo = res.data
					
					if((!userInfo.memberName)||(!userInfo.memberPhone)){
						Dialog.confirm({
						  title: '完善信息',
						  message:
						    '请前往设置完善信息！',
						})
						  .then(() => {
						    // on confirm
							this.$router.push("/means")
						  })
						  .catch(() => {
						    // on cancel
						  });
					}else{
						this.$router.push({
							name: "Subscribe",
							params: {
								code: 1,
								// siteInfo: JSON.stringify(this.siteInfo)
							}
						})
					}
					// this.authenticationInfo.businessLeadingPerson=res.data.memberName
					// this.authenticationInfo.tel=res.data.memberPhone
				})
				
				// this.$router.push({
				// 	path:"/subscribe",
				// 	query:{
				// 		code: 1,
				// 		siteInfo: JSON.stringify(this.siteInfo)
				// 	}
				// })
			},
			//跳转充值套餐
			toSetMeal() {
				this.$axios.get("member/base/memberInfo").then(res=>{
					
					var userInfo=res.data
					if(userInfo.memberType==2){
						//送样员
						Toast("请登录BOSS账号查看！")
						return;
					}else{
						this.$router.push({
							path: "/balance"
						})
					}
					
				})
				
			}
		}
	}
</script>

<style>
	.indexCell .van-cell {
		padding: 10px 5vw;
	}

	.indexCell .van-cell::after {
		border-bottom: 0;
	}

	/* 轮播 */
	.swipe {
		width: 90vw;
		height: 42.7vw;
		margin: auto;
		border-radius: 10px;
	}

	.swipe-img {
		width: 90vw;
		height: 42.7vw;
		border-radius: 10px;
	}

	/* 预约部分 */
	.indexImg {
		width: 44vw;
		height: 26.7vw;
	}

	.companyImg {
		width: 90vw;
		height: 37.3vw;
	}

	.introduceInfo img {
		width: 90vw;
		border-radius: 10px;
	}

	.introduceInfo video {
		width: 90vw;
		max-height: 300px;
	}
</style>
