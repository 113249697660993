<template>
	<div>
		<!--<div class="bookkeeping">
			 <div style="width: 100%;position: relative;">
				<div>
					<div class="text-20">￥480.00</div>
					<div class="text-14">记账金额</div>
				</div>
				<div style="position: absolute;top: 0;right: 5vw;">
					<div class="billBtn" @click="toRecord">
						销账记录
					</div>
				</div>
			</div> -->
			<!-- <div style="width: 100%;">
				<div style="position: relative;">
					<div>
						<div class="text-22 blod">{{bookkeepingInfo.totalUnpaid}}</div>
						<div class="text-16">累计未付</div>
					</div>

					<div style="position: absolute;top: 0;right: 5vw;" class="orderBtn" @click="checkOut">
						结账
					</div>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: space-around;padding-top: 20px;">
					<div>
						<div class="text-18">{{bookkeepingInfo.totalBooking}}</div>
						<div class="text-12">累计记账</div>
					</div>
					<div style="border-left: #FFFFFF 1px solid;height: 40px;"></div>
					<div>
						<div class="text-18">{{bookkeepingInfo.totalPaid}}</div>
						<div class="text-12">累计已付</div>
					</div>
				</div>
			</div> 

		</div>-->

		<!-- 明细 -->
		<div>
			<div>
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
						<van-skeleton :row="4" />
					</div>

				</div>
				<van-empty v-if="!showSkeleton&&bookkeepingInfo.length<=0" class="custom-image" :image="emptyImg"
					description="您还没有账单" />
				<div v-if="!showSkeleton&&bookkeepingInfo.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
							<div class="width82 backgroundWhite radius5 margin-bottom-10"
								v-for="(item,index) in bookkeepingInfo" :key="index">

								<div class="padding-bottom-10" style="border-bottom: #F5F5F5 1px solid;">
									<div class="text-14 ">
										站点名称:{{item.siteName}}
									</div>
									<div class="text-14 padding-top-5">
										累计记账:￥{{item.bookingAll}}
									</div>
									<div class="text-14 padding-top-5">
										累计已付:￥{{item.bookingPaid}}
									</div>
									<div class="text-14 padding-top-5">
										累计未付:￥{{item.bookingUnpay}}
									</div>
									
								</div>
								
								<div style="display: flex;flex-direction: row-reverse;padding-top: 10px;">
									<div class="margin-left-10" @click="toRecord(item)">
										<van-button :color="colorBtn" type="primary" round>销账记录</van-button>
									</div>
									<div class="margin-left-10" @click="checkOut(item)">
										<van-button :color="colorBtn" type="primary" round>立即销账</van-button>
									</div>
								</div>

							</div>
						</van-list>
					</van-pull-refresh>

				</div>

			</div>
		</div>

		<!-- 支付方式 -->
		<van-action-sheet v-model="showPayment" title="选择支付方式">
			<payment v-if="showPayment" :price="payPrice" :orderType="7" :custom="1" :orderId="orderId" :showOffline="false" :showIntegral="false" :showFrequency="false" @paySuccess="paySuccess"></payment>
		</van-action-sheet>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import payment from "@/components/payment.vue"
	import {
		Toast
	} from 'vant';
	export default {
		extends: BaseView,
		components: {
			payment
		},
		data() {
			return {
				title: "记账账单",
				showPayment: false,
				payPrice: 0,
				showSkeleton: true,
				refreshing: false,
				loading: false,
				finished: false,
				selectAll: false,
				result: [],
				selectTotal: 0,
				totalPrice: 0,
				bookkeepingInfo: [],
				orderId:0,
				param:{
					page:1,
					limit:10
				},
				bookkeeping: [],
				total:0
			}
		},
		created() {
			// this.getList()
			this.getCount()
		},
		methods: {
			//统计
			getCount(){
				this.$axios.get("/bill/booking/billMoney").then(res=>{
					this.bookkeepingInfo=res.data
					this.showSkeleton = false
					this.refreshing= false
					this.loading= false
					this.finished=true
				})
			},
			//列表
			getList(){
				
				this.$axios.get("/bill/booking/billBookingListPage",{
					params:this.param
				}).then(res=>{
					console.log("记账记录",res)
					
					this.refreshing= false
					this.loading= false
					if(this.param.page==1){
						this.bookkeeping=res.data
					}else{
						this.bookkeeping=this.bookkeeping.concat(res.data)
					}
					this.total=res.count
					this.finished=true
					
				})
			},
			toRecord(item) {
				this.$router.push({
					path: "/bookkeepingRecord?siteId="+item.siteId
				})
			},
			//全选
			clickAll() {
				// this.selectAll=!this.selectAll
				if (this.selectAll) { //全选
					this.result = []
					this.bookkeeping.forEach((item, index) => {
						this.result.push(index)
					})
					this.selectTotal = this.bookkeeping.length
					this.totalPrice = 0
					this.calculation()
				} else {
					this.result = []
					this.totalPrice = 0
					this.selectTotal = 0
				}


			},
			//单选
			clickCheck(value) {
				// console.log("选择",this.result)
				this.selectTotal = this.result.length
				if (this.selectTotal == this.bookkeeping.length) {
					this.selectAll = true
				} else {
					this.selectAll = false
				}
				this.totalPrice = 0
				this.calculation()
			},
			//计算价格
			calculation() {
				this.result.forEach((item) => {
					this.totalPrice = this.totalPrice + this.bookkeeping[item].price
				})
			},
			//结账
			checkOut(item) {
				console.log("结账",item)
				if(item.bookingUnpay<=0){
					Toast("您待结账的金额为0！")
					return;
				}
				this.showPayment = false
				this.orderId=(item.id).toString()
				this.payPrice=(item.bookingUnpay)
				console.log("结账this.payPrice",this.payPrice)
				this.showPayment = true
			},
			//支付成功
			paySuccess(){
				this.showPayment = false
				this.getCount()
				this.onRefresh()
			},
			onRefresh(){
				this.param.page=1
				this.finished=false
				this.refreshing=true
				// this.bookkeeping=[]
				// this.showSkeleton=true
				this.getCount()
			},
			onLoad(){
				if(this.total<=this.bookkeeping.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getCount()
			},
			//支付
			pay(e){
				console.log("支付",e)
			}
		}
	}
</script>

<style>
	.bookkeeping {
		width: 100vw;
		height: 170px;
		background: url(../../../assets/images/bookkeeping.png);
		background-size: 100% 100%;
		text-align: center;
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
