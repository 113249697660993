<template>
	<div>
		<van-form @submit="onSubmit">
			<div class="addSampleForm">
				<van-cell-group>
					<van-field clearable v-model="userInfo.memberName" name="sampleName" label="人员姓名" placeholder="请输入人员姓名" :rules="[{ required: true, message: '请输入人员姓名' }]" />
					<van-field clearable v-model="userInfo.memberPhone" name="tel" label="联系方式" placeholder="请输入联系方式" :rules="[{ pattern, message: '请输入正确的联系方式' }]" />
		
					
		
				</van-cell-group>
		
			</div>
		
			<!-- <div class="width90" style="margin: auto;margin-top: 80px;">
				<van-button :disabled="loadingBtn" class="width90" round block :color="colorBtn" native-type="submit">确认添加</van-button>
			</div> -->
		</van-form>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { Toast } from 'vant';
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "添加送样员",
				pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
				name:"",
				phone:"",
				ueserId:0,
				userInfo:{}
			}
		},
		created() {
			this.ueserId=this.$route.query.ueseId
			this.getUserInfo()
		},
		methods: {
			//查询用户信息
			getUserInfo() {
				this.$axios.get("member/base/memberInfo").then(res => {
					console.log("用户信息", res)
					this.userInfo = res.data
					if((!this.userInfo.memberName)||(!this.userInfo.memberPhone)){
						Toast("请前往设置完善信息")
						setTimeout(()=>{
							this.$router.push({
								path:"/means"
							})
						},2000)
					}else{
						Dialog.confirm({
						  title: '提示',
						  message: '确定绑定为送样员？',
						})
						  .then(() => {
						    // on confirm
							this.onSubmit()
						  })
						  .catch(() => {
						    // on cancel
						  });
					}
					// this.authenticationInfo.businessLeadingPerson=res.data.memberName
					// this.authenticationInfo.tel=res.data.memberPhone
				})
			},
			onSubmit(){
				// this.loadingBtn=true
				var value={
					bossId:this.ueserId
				}
				// value.boosId=this.ueserId
				console.log("新增",value)
				this.$axios.post("member/sample/insertSample",value).then(res=>{
					console.log("新增",res)
					// this.loadingBtn=false
					if(res.code==1){
						Toast("新增成功")
					}else{
						Toast(res.msg)
					}
				})
			}
		}
	}
</script>

<style>
	.addSampleForm {
		width: 90vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		margin-top: 15px;
	}
</style>
