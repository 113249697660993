<template>
	<div>
		<!-- 搜索 -->
		<van-search shape="round" @search="onSearch" @clear="onClear" v-model="siteValue" placeholder="搜索站点代码/编码/名称/所属区域" />

		<!-- 列表部分 -->
		<div class="width90 margin-top-10">
			<div v-if="showSkeleton">
				<van-skeleton class="sitrBox" style="height: 95px;" title :row="5" v-for="item in 5" :key="item"></van-skeleton>
			</div>

			<van-empty v-if="!showSkeleton&&siteList.length<=0" class="custom-image" :image="emptyImg" description="还没有站点~" />
			<div v-if="!showSkeleton&&siteList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
						<van-radio-group v-model="radio" ><!-- @change="changeSite" -->
							<div class="sitrBox" v-for="(list,key) in siteList" :key="key" @click="changeSite(key)">
								<div>
									<div class="text-14 blod" style="padding-bottom: 8px;">

										<van-radio icon-size="14" :name="key">{{list.siteName}}</van-radio>

									</div>
									<div class="text-12 line-height-17">站点地址:{{list.siteAdress}}</div>
									<div class="text-12 line-height-17">联系电话:{{list.siteTel}}</div>
									<div class="text-12 line-height-17">站点编码:{{list.siteNumber}}</div>
									<div class="text-12 line-height-17">站点代码:{{list.siteCode}}</div>
								</div>
								<div class="text-12 gray" style="text-align: right;">
									<div>{{list.siteRegion}}</div>
									<div>{{Number(list.distance).toFixed(2) }}km</div>
									<!-- <div>{{list.distance}}</div> -->
								</div>
							</div>
						</van-radio-group>
					</van-list>
				</van-pull-refresh>
			</div>

		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		bus
	} from "@/bus.js"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "切换站点",
				siteValue: '',
				refreshing:false,
				showSkeleton:false,
				finished:false,
				// backcolor: "#FFFFFF",
				loading: true,
				radio: -1,
				siteParam: {
					// page: 1,
					memberLatitude:25,//维度
					memberLongitude:102,//经度
				},
				total:0,
				siteList: [],
				code:1,//1首页2预约3补打
			}
		},
		created() {
			this.code=this.$route.query.code
			// setTimeout(() => {
			// 	this.loading = false
			// }, 2000)
			if(localStorage.getItem("longAlat")){
				var longAlat=JSON.parse(localStorage.getItem("longAlat"))
				
				this.siteParam.memberLatitude=longAlat.latitude
				this.siteParam.memberLongitude=longAlat.longitude
			}
			
			this.getSite()
		},
		methods: {
			//查询站点
			getSite() {
				this.$axios.get("site/base/checkSiteList", {
					params:this.siteParam
				}).then(res => {
					console.log("站点", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if (res.code == 1) {
						
						if(this.siteParam.page==1){
							this.siteList=res.data
						}else{
							this.siteList=this.siteList.concat(res.data)
						}
						this.total=res.count
						if(this.siteList.length<=this.total){
							this.finished = true
						}
					}
				})
			},
			changeSite(e) {
				console.log("选择", e)
				// if(this.code==1){
					
				// }
				bus.$emit("site"+this.code, this.siteList[e])
				// this.$router.go(-1);
				this.$router.back()
			},
			//搜索
			onSearch() {
				console.log("搜索", this.siteValue)
				this.refreshing = true
				this.finished = false
				this.siteParam.page=1
				this.siteParam.keyWord=this.siteValue
				this.siteList=[]
				this.getSite()
			},
			onClear() {
				console.log("清除文字", this.siteValue)
				this.refreshing = true
				this.finished = false
				this.siteParam.page=1
				delete this.siteParam.keyWord
				this.siteList=[]
				this.getSite()
			},
			//刷新
			onRefresh(){
				this.refreshing = true
				this.finished = false
				this.siteParam.page=1
				// this.siteList=[]
				this.getSite()
			},
			//加载
			onLoad(){
				// this.siteParam.page++
				// this.getSite()
			}
		}
	}
</script>

<style>
	/* .van-skeleton__row, .van-skeleton__title{
		background: #FFFFFF !important;
	} */
	.sitrBox {
		width: 82vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		padding: 10px 4vw !important;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.van-skeleton__title+.van-skeleton__row {
		margin-top: 0 !important;
	}

	.van-skeleton__row:not(:first-child) {
		margin-top: 5px !important;
	}
</style>
