<template>
	<div class="myCertificate">
		<!-- 搜索 -->
		<div>
			<div class="flex-row width90 input fixed"
				style="padding: 22px 5vw 7px 5vw;background: #FFFFFF;top: 0;z-index: 2;">
				<van-field class="searchInput" style="height: 34px;background: #F7F8FA;" v-model="certificateCode"
					:clearable="true" left-icon="search" placeholder="请输入证书编号" @clear="onRefresh"></van-field>
				<div class="search" @click="search">查询</div>
				<div style="margin-left: 10px;">
					<div class="screen" @click="screen" style="margin: auto;padding: 8px 0;">
						<div>筛选</div>
						<img style="width: 4px;height: 4px;" src="../../assets/images/arrow.png" />

					</div>
				</div>

			</div>
			<div style="height: 65px;"></div>

			<!-- 筛选 -->
			<van-popup v-model="showScreen" position="top" round>
				<div style="width: 100vw;max-height: 100vw;">
					<div class="width90" style="padding: 10px 0;">
						<div>
							<!-- <div>
								<div class="text-14 blod" style="padding: 10px 0;">类型分类</div>
								<div class="flex-row" style="flex-wrap: wrap;">
									<div v-for="(item,index) in material" :key="index" style="width: 33%;">
										<div class="van-ellipsis" style="width: 90%;" @click="clickMaterial(index)"
											:class="[materialState==index?'screenBox-act':'screenBox']">
											{{item.setValue}}
										</div>
									</div>
									
								</div>
							</div>
							
							<div>
								<div class="text-14 blod" style="padding: 10px 0;">贵金属</div>
								<div class="flex-row" style="flex-wrap: wrap;">
									<div v-for="(item,index) in nobleMetal" :key="index" style="width: 33%;">
										<div class="van-ellipsis" style="width: 90%;" @click="clickNobleMetal(index)"
											:class="[nobleMetalState==index?'screenBox-act':'screenBox']">
											{{item.setValue}}
										</div>
									</div>
									
								</div>
							</div> -->

							<div>
								<div class="text-14 blod" style="padding: 10px 0;">证书标识</div>
								<div class="flex-row" style="flex-wrap: wrap;">
									<div v-for="(item,index) in certificate" :key="index" style="width: 33%;">
										<div class="van-ellipsis" style="width: 90%;" @click="clickCertificate(index)"
											:class="[certificateState==index?'screenBox-act':'screenBox']">
											{{item.alias}}
										</div>
									</div>

								</div>

							</div>

							<div>
								<div class="text-14 blod" style="padding: 10px 0;">起止日期</div>
								<div class="betweenCenter text-14" style="flex-wrap: wrap;">
									<div @click="onTime('startTime')">{{startTime||'开始时间'}}</div>
									<div>——</div>
									<div @click="onTime('endTime')">{{endTime||'结束时间'}}</div>
								</div>

							</div>
						</div>
					</div>
					<div class="width90 backgroundWhite between"
						style="padding: 10px 0;position: sticky;bottom:0;z-index: 999;">
						<div class="reset" @click="reset">重置</div>
						<div class="determine" @click="onScreen">确定</div>
					</div>
				</div>
			</van-popup>

			<van-popup v-model="showTime" position="bottom" round z-index="9999">
				<van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate"
					:max-date="maxDate" @confirm="onConfirm" @cancel="onCancel" />
			</van-popup>
		</div>



		<!-- 列表 -->
		<div>
			<!-- 骨架屏 -->
			<div v-if="showSkeleton">
				<div v-for="i in 5" :key="i" class="width82 backgroundWhite radius10"
					style="padding: 10px 4px;margin: auto;margin-top: 10px;">
					<van-skeleton :row="2" />
					<van-skeleton avatar avatar-shape="square" avatar-size="80px" :row="4" style="padding-top: 10px;" />
				</div>
			</div>
			<van-empty v-if="!showSkeleton&&certificateList.length<=0" class="custom-image" :image="emptyImg"
				description="您还没有证书哦~" />
			<div v-if="!showSkeleton&&certificateList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
						<div v-for="(item,index) in certificateList" :key="index"
							class="width82 backgroundWhite radius10" style="margin: auto;margin-top: 10px;">
							<div class="flex-row text-12 gray borderBottom padding-bottom-10">
								<div style="flex: 1;">
									<div class="rowCenter" @click="onSelect(item,index)">
										<img class="img16" :src="item.select?require('@/assets/images/select2.png'):require('@/assets/images/unselect.png')"/>
										<div class="margin-left-8">证书编号:{{item.certificateNumber}}</div>
									</div>
									<!-- <van-checkbox :name="item.id" icon-size="14" @click="clicCheck(item)">
										
									</van-checkbox> -->
						
								</div>
								<div class="margin-left-10" style="text-align: right;" @click="toDetail(item)">
									<div class="rowCenter blue">
										<div>详情</div>
										<van-icon name="arrow" />
									</div>
						
									<!-- <div>订单编号:{{item.orderNum}}</div> -->
								</div>
						
							</div>
							<div class="flex-row padding-top-10" @click="toDetail(item)">
								<van-image fit="cover" class="img80 radius5"
									:src="item.certificateImgUrl"></van-image>
								<div class="margin-left-10 text-12">
									<div>鉴定结果:{{item.checkResult}}</div>
									<div style="padding: 5px 0;">总质量:{{item.totalMass}}</div>
									<div>颜色:{{item.color}}</div>
									<div style="padding: 5px 0;">形状:{{item.shape}}</div>
								</div>
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>

		</div>
		<div style="height: 60px;"></div>

		<!-- 底部确认补打 -->
		<div class="backgroundWhite between"
			style="width: 90vw;position: fixed;bottom: 50px;left: 0;padding: 5px 5vw;z-index: 2222;">
			<div class="rowCenter">
				<div @click="clickStorageBox">
					<van-badge :content="total" max="99">
						<img style="width: 34px;height: 34px;" src="../../assets/images/border.png" />
					</van-badge>
				</div>
				
				<div style="flex: 1;margin-left: 10px;">
					<div class="rowCenter" @click="onselectAll">
						<img class="img16" :src="selectAll?require('@/assets/images/select2.png'):require('@/assets/images/unselect.png')"/>
						<div class="margin-left-8">全选</div>
					</div>
				</div>
				
			</div>
			<div class="rowCenter">
				<div style="margin-right: 10px;">
					<span>共计:</span>
					<span class="blue">{{total}}</span>
					<span>件</span>
				</div>
				<van-button @click="print" :color="colorBtn" type="primary" round>确认补打</van-button>
			</div>

		</div>
		<van-popup v-model="showStorageBox" position="bottom" round>
			<div style="width: 100vw;max-height: 370px;">
				<div class="between text-14 borderBottom backgroundWhite"
					style="padding:10px 5vw;position: sticky;top:0;z-index: 999;">
					<div>
						<!-- <span>全选</span> -->
						<span style="color: #999999;">共计{{total}}件</span>
					</div>
					<!-- <van-radio-group v-model="radioStorage">
						<van-radio name="1" icon-size="14">
							<span>全选</span>
							<span style="color: #999999;">(共计{{total}}件)</span>
						</van-radio>
					</van-radio-group> -->
					<div class="rowCenter" @click="empty">
						<img class="img14" src="../../assets/images/trash.png" />
						<div style="color: #999999;">清空</div>
					</div>

				</div>

				<div style="padding:10px 5vw;">
					<div class="flex-row margin-bottom-10" v-for="(list,key) in storageBoxList" :key="key">
						<!-- <div style="margin: auto 0;">
							<van-radio name="1" icon-size="14"></van-radio>
						</div> -->
						<van-image fit="cover" class="img80 radius5 margin-left-10"
							:src="list.certificateImgUrl"></van-image>
						<div class="margin-left-10 flexWidth text-12">
							<div style="padding: 5px 0;">证书编号:{{list.certificateNumber}}</div>

							<div>鉴定结果:{{list.checkResult}}</div>
							<!-- <div style="padding: 5px 0;">形状:{{list.orderNum}}</div> -->
							<div class="between" style="padding: 5px 0;">
								<div>总质量:{{list.totalMass}}</div>
								<div>
									<van-stepper input-width="25px" button-size="25px" @change="changeStrpper(list)"
										v-model="list.num" />
								</div>
							</div>
						</div>
					</div>

					<!-- <van-radio-group v-model="radioStorage">
						
					</van-radio-group> -->
				</div>
				<div style="height: 110px;"></div>
			</div>
		</van-popup>

		<tabbar></tabbar>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	import Tabbar from "@/components/tabbar.vue"
	import {
		Sticky
	} from 'vant';
	export default {
		extends: BaseView,
		components: {
			Tabbar
		},
		data() {
			return {
				title: "我的证书",
				container: null,
				checked: false,
				showSkeleton: true,
				refreshing: false,
				finished: false,
				loading: false,
				total: 0,
				radioStorage: "1",
				showScreen: false,
				certificateCode: "",
				result: [],
				param: {
					page: 1,
					limit: 10
				},
				certificateList: [],
				certificateTotal: 0,
				materialState: -1, //材质
				material: [],
				nobleMetalState: -1, //贵金属
				nobleMetal: [],
				certificateState: -1, //证书标识
				certificate: [],
				showStorageBox: false,
				storageBoxList: [],
				startTime: "",
				endTime: "",
				minDate: new Date(1900, 0, 1),
				maxDate: new Date(),
				currentDate: new Date(),
				timeKey: "startTime",
				showTime: false,
				selectAll:false
			}
		},
		created() {
			console.log("created")
			this.getSort()
			this.getMyCertificate()
		},
		mounted() {
			// this.container = this.$refs.container;
		},
		methods: {

			//查询我的证书
			getMyCertificate() {
				console.log("证书param", this.param)
				this.$axios.get("/certificate/base/certificateList", {
					params: this.param
				}).then(res => {
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					console.log("我的证书", res)
					if (this.param.page == 1) {
						this.certificateList = res.data
					} else {
						this.certificateList = this.certificateList.concat(res.data)
					}
					this.certificateTotal = Number(res.count)
					console.log("***************", this.certificateList)
					console.log("**********", this.certificateTotal, "&&&&&", this.certificateList.length)
					if (this.certificateTotal <= this.certificateList.length) {
						this.finished = true
					}
					this.calculationNum()
				})
			},
			//查询分类
			getSort() {
				// localStorage.setItem("siteInfo",JSON.stringify(that.siteInfo))
				var site = JSON.parse(localStorage.getItem("siteInfo"));
				console.log('site', site)
				this.$axios.get("order/sort/orderConfigureSort", {
					params: {
						siteId: site.id
					}
				}).then(res => {
					console.log("分类", res)
					if (res.code == 1) {
						this.certificate = res.data.identification //标识
						this.nobleMetal = res.data.metal //贵金属
						this.material = res.data.material //材质
					}
				})
			},
			//搜索
			search() {
				console.log("搜索", this.certificateCode)
				this.param.certificateNumber = this.certificateCode
				this.certificateList=[]
				this.selectAll=false
				this.total=0
				this.param.page = 1
				this.finished = false
				this.showSkeleton=true
				this.getMyCertificate()
			},
			screen() {
				this.showScreen = true
				console.log("************")
			},
			//确定筛选
			onScreen() {
				delete this.param.textureOfMaterial
				delete this.param.nobleMetalType
				delete this.param.inspectionMark

				delete this.param.startDate
				delete this.param.endDate
				//材质分类
				if (this.materialState != -1) {
					this.param.textureOfMaterial = this.material[this.materialState].setValue
				}
				//贵金属
				if (this.nobleMetalState != -1) {
					this.param.nobleMetalType = this.nobleMetal[this.nobleMetalState].setValue
				}
				//证书标识
				if (this.certificateState != -1) {
					this.param.inspectionMark = this.certificate[this.certificateState].id
				}

				if (this.startTime) {
					this.param.startDate = this.startTime
				}
				if (this.endTime) {
					this.param.endDate = this.endTime
				}
				this.param.page = 1
				this.certificateList=[]
				this.selectAll=false
				this.total=0
				this.finished = false
				this.showSkeleton = true
				this.showScreen = false
				this.getMyCertificate()
			},
			//选择材质
			clickMaterial(index) {
				if (this.materialState == index) {
					//如果选择已选中的则清除选中
					this.materialState = -1
				} else {
					this.materialState = index
				}

			},
			clickNobleMetal(index) {
				if (this.nobleMetalState == index) {
					this.nobleMetalState = -1
				} else {
					this.nobleMetalState = index
				}

			},
			clickCertificate(index) {
				if (this.certificateState == index) {
					this.certificateState = -1
				} else {
					this.certificateState = index
				}

			},
			//多选
			clicCheck(e) {
				e.num = 1
				console.log("选择", this.result)
				if (this.storageBoxList.length == 0) {
					this.storageBoxList.push(e)
				} else {
					var state = 0
					this.storageBoxList.map((value, index) => {
						if (value.id == e.id) {
							this.storageBoxList.splice(index, 1)
							state = 1
						}
					})
					if (state == 0) {
						this.storageBoxList.push(e)
					}
				}
				this.calculationNum()
				// this.total = this.result.length
			},
			onSelect(item,index){
				item.select=!item.select
				this.certificateList.splice(index, item)
				this.calculationNum()
			},
			//全选
			onselectAll(){
				this.selectAll=!this.selectAll
				this.certificateList.map(val=>{
					val.select=this.selectAll
				})
				this.calculationNum()
			},
			clickStorageBox() {
				// if (!this.showStorageBox) {
				// 	this.storageBoxList = []
				// 	for (var i = 0; i < this.result.length; i++) {
				// 		this.storageBoxList.push(this.certificateList[this.result[i]])
				// 	}
				// 	console.log('收纳', this.storageBoxList)
				// }
				this.showStorageBox = !this.showStorageBox
				// this.showStorageBox = true
			},
			//清空收纳盒
			empty() {
				
				this.storageBoxList = []
				this.certificateList.map(val=>{
					val.select=false
				})
				this.selectAll=false
				this.total=0
				this.total = 0
				this.showStorageBox = false
			},

			//调整数量
			changeStrpper(list) {
				// console.log("调整数量", list)
				this.calculationNum()
			},
			//计算总数
			calculationNum() {
				// if (this.result.length == 0) {
				// 	this.total = 0
				// } else if (this.storageBoxList.length == 0) {
				// 	this.total = this.result.length
				// } else {
				// 	this.total = 0
				// 	this.storageBoxList.map(value => {
				// 		this.total = this.total + (value.num?value.num:1)
				// 	})
				// }
				var total=0//打印的数量
				var storageBoxList=[]
				this.certificateList.map(val=>{
					if(val.select){
						storageBoxList.push(val)
						total=total+(val.num?val.num:1)
					}
				})
				if(this.certificateList.length!=0&&(storageBoxList.length==this.certificateList.length)){
					this.selectAll=true
				}else{
					this.selectAll=false
				}
				this.total=total
				this.storageBoxList=storageBoxList
			},
			//确定补打
			print() {
				if (this.storageBoxList.length == 0) {
					Toast("请选择证书");
					return;
				}
				var ids = []
				// console.log("*******",this.storageBoxList)
				// console.log("*******&&&&&&&&&",this.result)
				this.storageBoxList.map(value => {

					// console.log("选择的",value)
					ids.push({
						id: value.id,
						num: value.num?value.num:1
					})
				})
				// if(this.storageBoxList.length>0){

				// }else{
				// 	this.result.map(value=>{
				// 		console.log("选择的",value)
				// 		ids.push({
				// 			id:this.certificateList[value].id,
				// 			num:1
				// 		})
				// 	})
				// }

				var idString = JSON.stringify(ids)
				this.$router.push({
					name: "CertificateOrder",
					params: {
						ids: idString,
						code: 1
					}
				})
			},
			//重置筛选
			reset() {
				this.materialState = -1
				this.nobleMetalState = -1
				this.certificateState = -1
				this.startTime = ''
				this.endTime = ''
				this.certificateList=[]
				this.selectAll=false
				this.total=0
				this.showScreen = false
				this.onRefresh()
			},
			//跳转详情
			toDetail(item) {
				this.$router.push({
					path: "/certificateDetail",
					query: {
						certificateId: item.id
					}
				})
			},
			//刷新
			onRefresh() {
				this.refreshing = true
				this.finished = false
				delete this.param.textureOfMaterial
				delete this.param.nobleMetalType
				delete this.param.inspectionMark
				delete this.param.certificateNumber
				delete this.param.startDate
				delete this.param.endDate

				this.materialState = -1
				this.nobleMetalState = -1
				this.certificateState = -1
				// this.certificateList=[]
				// this.showSkeleton=true
				this.param.page = 1
				this.getMyCertificate()
			},
			//加载更多
			onLoad() {
				console.log("*************", this.finished)
				if (this.certificateTotal <= this.certificateList.length) {
					console.log("*********ggg****", this.finished)
					this.finished = true
					return;
				}
				this.param.page++
				this.getMyCertificate()
			},
			//选择时间
			onTime(key) {
				this.timeKey = key
				this.showTime = true
			},
			onConfirm(e) {
				var time = this.formateDate2(new Date(e))
				this[`${this.timeKey}`] = time
				this.showTime = false
			},
			onCancel() {
				this.showTime = false
			},
			formateDate2(time) {
				let year = time.getFullYear()
				let month = time.getMonth() + 1
				let day = time.getDate()
				let h = time.getHours()
				let m = time.getMinutes()
				let s = time.getSeconds()
				return year + '-' + month + '-' + day + " " + h + ":" + m + ":" + s
			},
		}
	}
</script>

<style>
	.myCertificate .van-tabbar {
		z-index: 2222 !important;
	}

	.searchInput {
		border-top-left-radius: 34px;
		border-bottom-left-radius: 34px;
	}

	.input .van-cell {
		line-height: 14px !important;
	}

	.search {
		background: #0080FD;
		color: #FFFFFF;
		width: 50px;
		height: 34px;
		border-top-right-radius: 34px;
		border-bottom-right-radius: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 12px;
	}

	.screen {
		width: 46px;
		background: #F7F8FA;
		border-radius: 4px;
		text-align: center;
		margin-left: 5px;
		font-size: 13px;
		color: #696969;
		padding: 0 7px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: center;
	}

	.van-button {
		height: 40px !important;
	}


	/*筛选 */
	.screenBox {
		width: 30%;
		background: #F8F8F8;
		margin-top: 5px;
		line-height: 36px;
		text-align: center;
		font-size: 14px;
		border-radius: 5px;
	}

	.screenBox-act {
		width: 30%;
		background: #FFFFFF;
		color: #0080FD;
		border: #0080FD 1px solid;
		margin-top: 5px;
		line-height: 36px;
		text-align: center;
		font-size: 14px;
		border-radius: 5px;
	}

	.reset {
		width: 23.7vw;
		height: 40px;
		background: #F5F5F5;
		border-radius: 5px;
		text-align: center;
		line-height: 40px;
	}

	.determine {
		width: 62vw;
		height: 40px;
		background: linear-gradient(to left, #0080FD, #03B0F9);
		border-radius: 5px;
		text-align: center;
		line-height: 40px;
		color: #FFFFFF;
	}
</style>