<template>
	<div>
		<div class="balance">
			<div style="position: relative;">
				<div class="balanceWords">
					<div class="text-20">￥{{balanceInfo.balance}}</div>
					<div class="text-14">我的余额</div>
				</div>
				<div style="position: absolute;top: 24px;right: 5vw;">
					<div class="balanceBtn" @click="toRecord">
						使用记录
					</div>
				</div>

			</div>
		</div>

		<!-- 余额充值 -->
		<div class="width82 backgroundWhite radius10" style="margin-top: -30px;">
			<div class="text-12 blod" style="padding-bottom: 12px;">余额充值</div>
			<div class="blockDiv" v-if="showBalanceList">
				<div class="block" v-for="(item,index) in balanceList" :key="index" @click="clickPay(item)">
					<div class="blue text-18 blod">
						<span>{{item.setPrice}}</span>
						<span v-if="item.setPrice!='自定义'">元</span>
					</div>
					<div class="text-12">赠:{{item.giveMoney}}元</div>
					<div class="gray text-12">立即充</div>
				</div>
			</div>
			<div class="blockDiv" v-if="!showBalanceList">
				<div class="block" style="background: #F5F5F5;" v-for="i in 6" :key="i">
					
				</div>
			</div>
		</div>
		<!-- 充值记录 -->
		<div class="width82 backgroundWhite radius10 margin-top-10">
			<div class="text-12 blod">充值记录</div>
			<!-- 骨架屏 -->
			<div v-if="showSkeleton">
				<div class="borderBottom paddingtb12" v-for="i in 5" :key="i">
					<van-skeleton title :row="2" />
				</div>

			</div>

			<van-empty v-if="!showSkeleton&&rechargeList.length<=0" class="custom-image" :image="emptyImg" description="您还没有充值记录哦~" />
			<div v-if="!showSkeleton&&rechargeList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
						<div class="borderBottom paddingtb12" v-for="(item,index) in rechargeList" :key="index">
							<div class="between" style="padding-bottom: 5px;">
								<div class="text-14">{{item.title}}</div>
								<div class="text-14 blod">￥{{item.profitMoney}}</div>
							</div>
							<div class="text-12" style="color: #A5A6AB;padding-bottom: 3px;">订单:{{item.itemCode}}</div>
							<div class="text-12" style="color: #A5A6AB;">时间:{{item.createDate}}</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>

		<!-- 支付 -->
		<van-action-sheet v-model="showPayment" title="选择支付" @close="closePayment">
			<wxpayment v-if="showPayment" :price="price" :orderId="rechargeId" :custom="custom" @paySuccess="paySuccess" @payFail="payFail"></wxpayment>
		</van-action-sheet>


		<!-- 自定义支付 -->
		<!-- <van-action-sheet v-model="showCustomPay" title="选择支付">
			<wxpayment v-if="showCustomPay" :price="null" :custom="1" @paySuccess="paySuccess"></wxpayment>
			
		</van-action-sheet> -->
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import wxpayment from "@/components/wxpayment.vue"
	import {Toast} from 'vant';
	export default {
		extends: BaseView,
		components: {
			wxpayment
		},
		data() {
			return {
				title: "余额",
				showBalanceList:false,//充值
				showSkeleton: true,
				showPayment: false,
				showCustomPay: false,
				refreshing:false,
				finished:false,
				loading:false,
				price: 0,
				custom:0,//1自定义
				customPrice: '',
				balanceInfo:{},
				balanceList: [],
				param: {
					page: 1,
					limit:10,
					profitType:1
				},
				balanceParam:{
					page:1
				},
				total: 0,
				rechargeList: [],
				orderId:"0",
				userInfo:{},
				rechargeId:0
			}
		},
		created() {
			this.getRechargeList()
			this.getBalance()
			this.getBalanceList()
			this.getUserInfo()
		},
		methods: {
			//查询用户信息
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					console.log("用户信息",res)
					this.userInfo=res.data
				})
			},
			//查询充值记录
			getRechargeList() {
				this.$axios.get("member/balance/profitBalanceListPage", {
					params: this.param
				}).then(res => {
					console.log("查询充值记录", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if (this.param.page == 1) {
						this.rechargeList = res.data
					} else {
						this.rechargeList = this.rechargeList.concat(res.data)
					}
					// this.total = res.count
					if(this.rechargeList.length>=this.total){
						this.finished=true
					}

				})
			},
			//查询余额
			getBalance(){
				this.$axios.get("member/balance/balance").then(res=>{
					console.log("余额",res)
					if(res.code==1){
						this.balanceInfo=res.data
					}
				})
			},
			//查询余额充值列表
			getBalanceList(){
				this.$axios.get("meal/base/balanceList",{params:this.balanceParam}).then(res=>{
					console.log("余额充值列表",res)
					this.showBalanceList=true
					if(res.code==1){
						this.balanceList=res.data
						// this.balanceList.push({
						// 	setPrice:"自定义"
						// })
					}
				})
			},
			toRecord() {
				this.$router.push({
					path: "/balanceRecord"
				})
			},
			//支付
			clickPay(item) {
				console.log("showPayment",this.showPayment)
				// if(this.userInfo.memberType!=1){
				// 	Toast("您不是商家，没有充值权限！")
				// 	return;
				// }
				if(this.loadingBtn){
					return;
				}
				this.loadingBtn=true
				
				if (item.setPrice == '自定义') {
					this.price=null
					this.custom=1
					this.showPayment = true
					// this.showCustomPay = true
				} else {
					this.custom=0
					this.price = item.setPrice
					this.rechargeId=item.id
					this.loadingBtn=false
					this.showPayment = true
					// this.$axios.post("/meal/base/rechargeOrder",{
					// 	rechargeId:item.id
					// }).then(res=>{
					// 	console.log("余额",res)
					// 	this.loadingBtn=false
					// 	this.orderId=res.data
					// 	this.showPayment = true
					// })
				}
			},
			//关闭支付弹框
			closePayment(){
				this.loadingBtn=false
			},
			//支付成功
			paySuccess(){
				this.showPayment=false
				this.getBalance()
				this.onRefresh()
			},
			//失败
			payFail(){
				this.showPayment=false
			},
			onRefresh(){
				this.refreshing = true
				this.finished = false
				// this.showSkeleton=true
				this.param.page=1
				// this.rechargeList=[]
				this.getRechargeList()
			},
			onLoad(){
				if(this.rechargeList.length>=this.total){
					this.finished=true
					return;
				}
				this.param.page++
				this.getRechargeList()
			}
		}
	}
</script>

<style>
	.balance {
		width: 100vw;
		height: 120px;
		background: url(../../../assets/images/balanceBg.png);
		background-size: 100%;
	}

	.balanceWords {
		width: 85px;
		margin: auto;
		color: #FFFFFF;
		padding-top: 24px;
		text-align: center;
	}

	.balanceBtn {
		width: 70px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		border-radius: 28px;
		color: #FFFFFF;
		border: #FFFFFF 1px solid;
		font-size: 12px;
	}

	/* 宫格 */
	.blockDiv {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.block {
		width: calc(calc(100% / 3) - 10px);
		margin: 5px;
		padding: 19px 0;
		box-sizing: border-box;
		background: #EDF8FF;
		border-radius: 5px;
		text-align: center;
	}

	.customPay .van-field__control {
		font-size: 16px;
	}
</style>
