
<template>
	<div>
		<div class="width90">
			<div class="width90 flex-row modeBox">
				<div :class="[type==1?'mailBg':'topBox']" class="topBoxWords" @click="clickMode(1)">门店</div>
				<div :class="[type==2?'selfBg':'topBox']" class="topBoxWords" @click="clickMode(2)">线下</div>
				
			</div>
			
			<div v-if="type==1" class="backgroundWhite" style="font-size: 14px;padding: 16px 16px 0 16px;">
				
				<van-radio-group v-model="state" direction="horizontal">
				  <van-radio name="2" icon-size="14px" @click="changeState">邮寄</van-radio>
				  <van-radio name="1" icon-size="14px" @click="changeState">自提</van-radio>
				</van-radio-group>
				
			</div>
			<div class="adress betweenCenter backgroundWhite" v-if="type==1">
				<div v-if="siteInfo">
					<div class="blod text-14">
						{{siteInfo.siteName}}
					</div>
					<div class="text999 text-12" style="padding-top: 5px;">
						{{siteInfo.siteAdress}}
					</div>
				</div>
				<div v-if="!siteInfo" class="text-14">
					选择站点
				</div>
				<div class="text-12">
					<div class="rowCenter blue" @click="toSite">
						<div>切换</div>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			
			<div v-if="state=='2'&&type==1" @click="toAdress">
				
				<div class="adress bottomRadius betweenCenter" v-if="adressInfo!=''">
					<div>
						<div class="blod text-14 rowCenter">
							<div>{{adressInfo.contactName}}</div>
							<div style="padding-left: 14px;">{{adressInfo.telephone}}</div>
						</div>
						<div class="text999 text-12" style="padding-top: 5px;">
							{{adressInfo.region}}{{adressInfo.address}}
						</div>
					</div>
					<div class="text-12">
						<div class="rowCenter blue" >
							<div>选择</div>
							<van-icon name="arrow" />
						</div>
					</div>
				</div>
				<div class="text-14 adress" style="text-align: center;" v-if="adressInfo==''">
					<span>亲，请选择本次证书补打的 </span>
					<span class="blue">收货地址</span>
				</div>
			</div>
			
			<div v-if="state=='1'&&type==1" class="bottomRadius backgroundWhite">
				<!-- <div class="adress betweenCenter">
					<div v-if="siteInfo">
						<div class="blod text-14">
							{{siteInfo.siteName}}
						</div>
						<div class="text999 text-12" style="padding-top: 5px;">
							{{siteInfo.siteAdress}}
						</div>
					</div>
					<div v-if="!siteInfo" class="text-14">
						选择站点
					</div>
					<div class="text-12">
						<div class="rowCenter blue" @click="toSite">
							<div>切换</div>
							<van-icon name="arrow" />
						</div>
					</div>
				</div> -->
				<div style="padding-bottom: 10px;">
					<van-field v-model="phone" name="phone" label="预留电话" input-align="right" placeholder="请输入联系电话" />
					<van-field readonly clickable name="datetimePicker" :value="time" label="自提时间" input-align="right" right-icon="arrow"
					 placeholder="请选择自提时间" @click="showPicker = true" />
				</div>
				
				<van-popup v-model="showPicker" position="bottom" :style="{ height: '40%' }">
					<van-datetime-picker
					  v-model="currentDate"
					  type="datetime"
					  title="请选择自提时间"
					  :min-date="minDate"
					  @confirm="confirmTime"
					  @cancel="showPicker = false"
					/>
				</van-popup>

				<!-- <van-calendar v-model="showPicker" @confirm="onConfirm" /> -->
			</div>
		</div>
		
		<!-- 证书列表 -->
		<div class="width82 backgroundWhite radius10" style="margin-top: 10px;">
			<div class="text-14 blod borderBottom" style="padding: 10px 0;">补打证书</div>
			<!-- 骨架屏 -->
			<div v-if="showSkeleton">
				<div class="borderBottom" style="padding: 10px 0;">
					<van-skeleton avatar avatar-shape="square" avatar-size="80px" :row="3" style="padding-top: 10px;" />
					<div class="borderBottom" style="margin: 10px 0;"></div>
					<van-skeleton :row="2" />
					<div class="borderBottom" style="margin: 10px 0;"></div>
					<van-skeleton :row="1" />
				</div>
			</div>
			<div v-if="!showSkeleton">
				<div class="borderBottom" style="padding: 10px 0;">
					<div class="flex-row margin-bottom-10" v-for="(list,key) in certificateList" :key="key">
						
						<van-image fit="cover" class="img80 radius5 margin-left-10" :src="list.certificateImgUrl"></van-image>
						<div class="margin-left-10 flexWidth text-12">
							<div style="padding: 5px 0;">证书编号:{{list.certificateNumber}}</div>
							<!-- <div style="padding: 5px 0;">订单编号:{{list.orderNum}}</div> -->
							<div>鉴定结果:{{list.checkResult}}</div>
							<div class="between" style="padding: 5px 0;">
								<div>总质量:{{list.totalMass}}</div>
								<div>
									<van-stepper input-width="25px" button-size="25px" @change="changeStrpper(list)" v-model="list.num" />
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="borderBottom text-12" style="padding: 10px 0;">
					<div class="between">
						<div>证书费用</div>
						<div>￥{{Number(freeInfo.certificateCost).toFixed(2)}}</div>
					</div>
					<div class="between" style="padding-top: 5px;" v-if="state==2&&type==1">
						<div>物流费用</div>
						<div>￥{{Number(freeInfo.logisticsCost).toFixed(2)}}</div>
					</div>
				</div>
				
				<div class="text-14" style="padding-top: 10px;text-align: right;">
					<span>共</span>
					<span class="orange blod">{{certificateTotal}}</span>
					<span>件，合计费用:</span>
					<span class="orange blod">￥{{Number(freeInfo.allCost).toFixed(2)}}</span>
				</div>
				
			</div>
			
		</div>
		<div style="height: 60px;"></div>
		<!-- 底部确认补打 -->
		<div class="backgroundWhite between" style="width: 90vw;position: fixed;bottom: 0;left: 0;padding: 5px 5vw;">
			<div class="text-14" style="padding-top: 10px;text-align: right;">
				<span>共</span>
				<span class="orange blod">{{certificateTotal}}</span>
				<span>件，合计费用:</span>
				<span class="orange blod">￥{{Number(freeInfo.allCost).toFixed(2)}}</span>
			</div>
			<div>
				<van-button :disabled="loadingBtn" @click="print" :color="colorBtn" type="primary" round>立即补打</van-button>
			</div>
		
		</div>
		
		<!-- 支付方式 -->
		<van-action-sheet v-model="showPayment" title="选择支付方式" >
			<payment :price="freeInfo.allCost" :orderId="orderId" :orderType="5" :showOffline="false" :showIntegral="false" :showFrequency="false" @paySuccess="paySuccess"></payment>
		</van-action-sheet>

	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import payment from "@/components/payment.vue"
	import {bus} from "@/bus.js"
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		components:{
			payment
		},
		data() {
			return {
				minDate: new Date(),
				showPayment:false,
				showSkeleton:true,
				currentDate: new Date(),
				title: "确认补打",
				showPicker: false,
				type:1,//2线下1门店
				state: "2", //2邮寄1自提
				phone:"",
				time:"",
				adressInfo:"",
				siteInfo:"",
				ids:"",
				list:[],
				freeInfo:{},//费用
				siteParam:{
					memberLatitude:25, //纬度
					memberLongitude: 102//经度
				},
				latelySite:"",//最近站点
				certificateTotal:0,
				certificateList: [],
				certificateCostParam:{
					certificateNumber:1,
				},
				certificateCost:0,//证书费用
				mailCost:0,//物流费用
				totalPrice:0,//总费用
				orderId:"0",//订单id
			}
		},
		activated(){
			console.log("activated",this.$route.params)
			var code=this.$route.params.code
			if(code==1){
				Object.assign(this.$data, this.$options.data.call(this));//重置页面数据
				this.list=JSON.parse(this.$route.params.ids)
				console.log("list",this.list)
				var ids=[]
				this.list.map(value=>{
					ids.push(value.id)
				})
				this.ids=ids.toString()
				this.getCertificate()
				// if(this.ids!=ids.toString()){
					
				// }
				
				// this.phone=""
				// this.time=""
				// this.state=0
			}
			if(!this.adressInfo){
				this.getAdress()
			}
			
			
			// bus.$on("setAdress",(value)=>{
			// 	console.log("地址a",value)
			// 	this.adressInfo=value
			// })
		},
		deadctivated(){
			console.log("deadctivated")
		},
		created() {
			console.log("mindata",new Date())
			// this.list=JSON.parse(this.$route.query.ids) 
			// console.log("list",this.list)
			// var ids=[]
			// this.list.map(value=>{
			// 	ids.push(value.id)
			// })
			// this.ids=ids.toString()
			// this.getCertificate()
			
		
			bus.$on("setAdress",(value)=>{
				console.log("地址a",value)
				this.adressInfo=value
			})
			bus.$on('site3',(value)=>{
				this.siteInfo=value
				console.log("站点",this.siteInfo)
				this.certificateCostParam.siteId=this.siteInfo.id
				this.getCertificateCost()//查询费用
			});
			
			
		},
		methods: {
			//补打的证书
			getCertificate(){
				this.$axios.get("/certificate/certificateOrder/certificateConfirmInfo",{
					params:{
						certificateIds:this.ids
					}
				}).then(res=>{
					console.log("查询证书",res)
					var certificateList=res.data
					this.list.map((value,index)=>{
						// console.log("***index******",index)
						if(value.id==certificateList[index].id){
							certificateList[index].num=value.num
						}
					})
					console.log("*******证书********",certificateList)
					
					this.certificateList=certificateList
					//计算总数
					this.calculationNum()
					//查询最近站点
					this.getLatelySite()
					
					
				})
			},
			//查询最近站点
			getLatelySite(){
				var longAlat=JSON.parse(localStorage.getItem("longAlat"))
				this.$axios.get("/site/base/latelySite",{
					params: {
						memberLatitude:longAlat.latitude,
						memberLongitude:longAlat.longitude
					}
				}).then(res=>{
					console.log("最近站点",res)
					this.latelySite=res.data
					this.siteInfo=res.data
					this.certificateCostParam.siteId=this.latelySite.id
					
					//查询证书费用
					this.getCertificateCost()
					
					
				})
			},
			//调整数量
			changeStrpper(list) {
				console.log("调整数量", this.certificateList)
				this.calculationNum()
				this.getCertificateCost()
			},
			//计算总数
			calculationNum(){
				this.certificateTotal=0
				this.certificateList.map((value,index)=>{
					// console.log("***index******",index)
					this.certificateTotal=this.certificateTotal+value.num
				})
				this.certificateCostParam.certificateNumber=this.certificateTotal
				
				
			},
			changeState(){
				console.log("*********")
				this.getCertificateCost()
				
			},
			//查询证书费用
			getCertificateCost(){
				var arr=[]
				this.certificateList.map((value,index)=>{
					arr.push({
						id:value.id,
						inspectionMarkId:value.inspectionMarkId,
						num:value.num
					})
				})
				console.log("证书费用param",this.certificateCostParam)
				var param={//encodeURIComponent
					certificateJson:JSON.stringify(arr),
					printMode:this.type
				}
				if(this.type==2){
					//线下
					param.receiveType=1
					delete param.siteId
					
				}else{
					//门店
					param.receiveType=this.state
					// param.siteId=this.latelySite.id
					param.siteId=this.certificateCostParam.siteId
				}
				this.$axios.post("/certificate/certificateOrder/certificateCost",param).then(res=>{
					console.log("证书费用",res)
					this.showSkeleton=false
					if(res.data){
						this.freeInfo=res.data		
					}else{
						this.freeInfo={
							allCost:0,
							certificateCost:0,
							logisticsCost:0
						}
					}
								
					
				})
			},
			//查询物流费用
			getMailCost(){
				this.$axios.get("/certificate/certificateOrder/mailCost",{
					params:{
						certificateNumber:this.certificateTotal,
						receiveType:1//1 邮寄 2 自提
					}
				}).then(res=>{
					console.log("物流费用",res)
					this.mailCost=res.data
					this.calculationPrice()
				})
			},
			//计算总价
			calculationPrice(){
				this.showSkeleton=false
				if(this.state==2){//2邮寄1自提
					this.totalPrice=this.certificateCost+this.mailCost
				}else{
					this.totalPrice=this.certificateCost
				}
			},
			//查询默认地址
			getAdress(){
				this.$axios.get("/certificate/certificateOrder/defaultAddress").then(res=>{
					console.log("默认地址",res)
					if(res.data && res.data!="没有默认收货地址！"){
						this.adressInfo=res.data
					}
				})
			},
			//选择地址
			toAdress(){
				this.$router.push({
					path:"/adress",
					query:{
						adressState:1
					}
				})
			},
			//选择站点
			toSite(){
				this.$router.push({
					path:"/site",
					query:{
						code:3
					}
				})
			},
			clickMode(state) {
				if(state==2){
					//判断，只能打印中卡
					//this.inspectionStr=e.setValue.substr(0,1)
					var item=0//0只有中卡1有其他卡
					this.certificateList.map(val=>{
						var inspectionStr=val.inspectionMark.substr(0,1)
						console.log("inspectionStr",inspectionStr!='M'&&inspectionStr!='K'&&inspectionStr!='Q'&&inspectionStr!='H')
						if(inspectionStr!='M'&&inspectionStr!='K'&&inspectionStr!='Q'&&inspectionStr!='H'){
							item=1
						}
					})
					if(item==1){
						Toast("线下只能打印中卡证书，请选择门店打印")
						return;
					}
				}
				this.type = state
				this.getCertificateCost()
			},
			//选择时间
			confirmTime(date){
				console.log("选择时间",date)
				this.time = this.formateDate(date)
				this.showPicker=false
			},
			//确定补打
			print(){
				if(this.type==1){
					if(this.state==1){
						if(!this.pattern.test(this.phone)){
							Toast("请输入正确的手机号")
							return;
						}
						if(!this.time){
							Toast("请选择自提时间")
							return;
						}
					}else if(this.state==2){
						if(!this.adressInfo){
							Toast("请选择地址")
							return;
						}
					}
				}
				
				this.loadingBtn=true
				var orderParam={
					voucherType:1
				}
				var certificateList=[]
				this.certificateList.map(value=>{
					certificateList.push({
						id:value.id,
						inspectionMarkId:value.inspectionMarkId,
						num:value.num,
						checkResult:value.checkResult,
						totalMass:value.totalMass,
						certificateNumber:value.certificateNumber,
						color:value.color,
						certificateImgUrl:value.certificateImgUrl
					})
				})
				
				orderParam.certificateJson=JSON.stringify(certificateList) //证书id+数量
				orderParam.mailCost=this.freeInfo.logisticsCost//物流费用
				orderParam.certificateCost=this.freeInfo.certificateCost//证书费用
				orderParam.cost=this.freeInfo.allCost//合计费用
				orderParam.printMode=this.type//打印方式
				if(this.type==2){
					orderParam.receiveType=1
				}else{
					orderParam.receiveType=this.state//提货方式 2邮寄1自提
				}
				
				orderParam.siteId=this.siteInfo.id//站点id
				orderParam.siteName=this.siteInfo.siteName//站点名称
				orderParam.siteAddress=this.siteInfo.siteAdress//站点地址
				if(this.type==1){
					if(this.state==2){//2邮寄，1自提
						
						orderParam.receiveAddress= this.adressInfo.region+this.adressInfo.address//地址
						orderParam.receiverName=this.adressInfo.contactName//名字
						orderParam.receiverTel=this.adressInfo.telephone//电话
					}else{
						orderParam.receiverTel=this.phone//预留电话
						orderParam.receiveTime=this.time//自提时间
					}
				}
				
				console.log("补打订单",orderParam)
				setTimeout(()=>{
					this.loadingBtn=false
				},2000);
				// this.showPayment=true
				this.$axios.post("/certificate/certificateOrder/certificatePlayNow",orderParam).then(res=>{
					console.log("提交订单",res)
					if(res.code==1){
						this.orderId=res.data
						this.showPayment=true
						this.loadingBtn=false
					}
				})
			},
			//支付成功
			paySuccess(){
				this.$router.replace({
					path:"/myCertificateOrder"
				})
			}
		}
	}
</script>

<style>
	.modeBox {
		margin-top: 10px;
		background: #EDF8FF;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;

	}

	.topBox {
		color: #7D7E83;
		background: #EDF8FF;
		text-align: center;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}

	.topBoxWords {
		width: 45vw;
		text-align: center;
		line-height: 41px;
		font-size: 12px;
	}

	.mailBg {
		width: 45vw;
		height: 41px;
		background: url(../../assets/images/mailBg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.selfBg {
		width: 45vw;
		height: 41px;
		background: url(../../assets/images/selfBg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		border-top-right-radius: 10px;
	}

	.bottomRadius {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.adress {
		width: 82vw;
		padding: 19px 4vw;
		background: #FFFFFF;

	}
</style>
